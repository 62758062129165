import Breadcrumb from "components/shared/breadcrumb/Breadcrumb";
import Switch from "components/shared/switch/Switch";
import TextHeading from "components/ui/teaxtHeading/TextHeading";
import { adminOrdersBreadcrumb } from "data/breadcrumb";
import { adminOrderById } from "data/switchList";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { ADMIN_CLEAR_ORDER_BY_ID_DATA } from "store/actionTypes";
import { fetchOrderById } from "store/actions/admin/orders";
import { fetchAllVenders } from "store/actions/admin/users";
import Executors from "./executors/Executors";
import MainInfo from "./mainInfo/MainInfo";
import Verification from "./verification/Verification";
import { useTranslation } from "react-i18next";

const OrderByIdAdmin = ({
  order_by_id_data,
  fetchOrderById,
  ADMIN_CLEAR_ORDER_BY_ID_DATA,
  fetchAllVenders,
}) => {
  const { i18n } = useTranslation();
  const { id } = useParams();
  const [activeSwitch, setActiveSwitch] = useState("mainInfo");

  useEffect(() => {
    fetchOrderById(id);
    fetchAllVenders(1, 100, null);

    return () => {
      ADMIN_CLEAR_ORDER_BY_ID_DATA();
    };
  }, []);

  return (
    order_by_id_data && (
      <div className="contentBlock">
        <Breadcrumb
          list={adminOrdersBreadcrumb.map((e) => i18n.t(e.title))}
          activeLink={id}
        />
        <TextHeading text={`${i18n.t("Order")} ${id}`} />
        <Switch
          items={adminOrderById.map((e) => ({ ...e, title: i18n.t(e.title) }))}
          activeItem={activeSwitch}
          onSwitch={setActiveSwitch}
          style={{
            marginTop: 24,
            marginBottom: 16,
          }}
          className="switch miniSwitch"
        />
        {activeSwitch === "mainInfo" && <MainInfo />}
        {activeSwitch === "executors" && <Executors />}
        {activeSwitch === "verification" && <Verification />}
      </div>
    )
  );
};

function mapStateToProps(state) {
  return {
    order_by_id_data: state.adminOrderById.order_by_id_data,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchOrderById: (id) => dispatch(fetchOrderById(id)),
    fetchAllVenders: (page, size, params) =>
      dispatch(fetchAllVenders(page, size, params)),
    ADMIN_CLEAR_ORDER_BY_ID_DATA: () =>
      dispatch({ type: ADMIN_CLEAR_ORDER_BY_ID_DATA }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderByIdAdmin);
