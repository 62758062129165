import RejectVerificationOrderForm from "components/components/forms/modal/verificationOrder/rejectVerificationOrder/RejectVerificationOrderForm";
import { useTranslation } from "react-i18next";

const RejectVerificationOrder = () => {
  const { i18n } = useTranslation();

  return (
    <div style={{ maxWidth: 657 }}>
      <p
        className="mainText p24_600 p_mb8"
        style={{ textAlign: "center", width: "30vw" }}
      >
        {i18n.t("RejectionOfCompletedWork")}
      </p>
      <RejectVerificationOrderForm />
    </div>
  );
};

export default RejectVerificationOrder;
