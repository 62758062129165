import { notification } from "antd";
import SimpleButton from "components/shared/buttons/simpleButton/SimpleButton";
import i18n from "i18next";
import ban from "assets/images/icons/ban.svg";
import info_circle_red from "assets/images/icons/info_circle_red.svg";
import warning from "assets/images/icons/warning.svg";

export const successNotification = (text, description) => {
  notification.open({
    message: <p className="activeText p14_500">{i18n.t(text)}</p>,
    description: (
      <div className="subtext p14_400">
        <p>{i18n.t(description)}</p>
      </div>
    ),
  });
};

export const errorNotification = (text, description) => {
  notification.open({
    message: <p className="errorColor p14_500">{i18n.t(text)}</p>,
    description: (
      <div className="subtext p14_400">
        <p>{i18n.t(description)}</p>
        <p>{i18n.t("PleaseTryAgain")}</p>
      </div>
    ),
    icon: <img src={ban} />,
  });
};

export const errorNotificationWithButton = (
  api,
  text,
  description,
  buttonTitle,
  confirm
) => {
  const btn = (
    <div className="deleteNotificationButtons">
      <SimpleButton
        title={i18n.t(buttonTitle)}
        className="p14_400 errorColor"
        style={{
          padding: "7px 16px",
          border: "1px solid #F44336",
          borderRadius: "4px",
          backgroundColor: "white",
        }}
        onClick={() => {
          confirm();
          api.destroy();
        }}
      />
    </div>
  );
  api.open({
    message: <p className="errorColor p14_500">{i18n.t(text)}</p>,
    description: (
      <div className="subtext p14_400">
        <p>{i18n.t(description)}</p>
        <p>{i18n.t("PleaseTryAgain")}</p>
      </div>
    ),
    btn,
    icon: <img src={ban} />,
  });
};

export const warningNotification = (api, confirm, description) => {
  const btn = (
    <div className="deleteNotificationButtons">
      <SimpleButton
        title={i18n.t("Cancel")}
        className="p14_400 mainText"
        style={{
          backgroundColor: "white",
          marginRight: 16,
        }}
        onClick={() => api.destroy()}
      />
      <SimpleButton
        title={i18n.t("Confirm")}
        className="p14_400 errorColor"
        style={{
          padding: "7px 16px",
          border: "1px solid #F44336",
          borderRadius: "4px",
          backgroundColor: "white",
        }}
        onClick={confirm}
      />
    </div>
  );
  api.open({
    message: <p className="errorColor p14_500">{i18n.t("Warning")}</p>,
    description: (
      <div className="subtext p14_400">
        <p>
          {i18n.t("ToConfirm")} {i18n.t(description)}
        </p>
        {/* <p>{description}</p> */}
      </div>
    ),
    btn,
    icon: <img src={info_circle_red} />,
  });
};

export const warningNotificationWithoutConfirmation = (description) => {
  notification.open({
    message: <p className="warnintText p14_500">{i18n.t("Warning")}</p>,
    description: <div className="subtext p14_400">{i18n.t(description)}</div>,
    icon: <img src={warning} />,
  });
};

export const confirmNotification = (api, confirm, description) => {
  const btn = (
    <div className="deleteNotificationButtons">
      <SimpleButton
        title={i18n.t("Cancel")}
        className="p14_400 mainText"
        style={{
          backgroundColor: "white",
          marginRight: 16,
        }}
        onClick={() => api.destroy()}
      />
      <SimpleButton
        title={i18n.t("Confirm")}
        className="p14_400 warnintText whiteButton"
        onClick={confirm}
      />
    </div>
  );
  api.open({
    message: <p className="warnintText p14_500">{i18n.t("Warning")}</p>,
    description: (
      <div className="subtext p14_400">
        <p>{i18n.t("ToConfirm")}</p>
        <p>{i18n.t(description)}</p>
      </div>
    ),
    btn,
    // icon: (
    //     <img src={info_circle_red} />
    // )
  });
};
