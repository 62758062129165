import EmailLogin from "components/components/forms/authentication/login/emailLogin/EmailLogin";
import AuthenticationBlock from "components/ui/authenticationBlock/AuthenticationBlock";
import TextHeading from "components/ui/teaxtHeading/TextHeading";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "../style.css";

function Login() {
  const { i18n } = useTranslation();
  return (
    <AuthenticationBlock>
      <TextHeading text={i18n.t("Entrance")} style={{ textAlign: "center" }} />
      <EmailLogin />
      <Link to={"/passwordRecovery"} className="p14_400 activeText">
        {i18n.t("ForgotYourPassword")}
      </Link>
    </AuthenticationBlock>
  );
}

export default Login;
