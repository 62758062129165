import AcceptVerificationOrderForm from "components/components/forms/modal/verificationOrder/acceptVerificationOrder/AcceptVerificationOrderForm";
import { useTranslation } from "react-i18next";

const AcceptVerificationOrder = () => {
  const { i18n } = useTranslation();
  return (
    <div>
      <p
        className="mainText p24_600 p_mb8"
        style={{ textAlign: "center", width: "30vw" }}
      >
        {i18n.t("ConfirmationOfCompletedWork")}
      </p>
      <AcceptVerificationOrderForm />
    </div>
  );
};

export default AcceptVerificationOrder;
