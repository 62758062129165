export const createQuery = (search, order_by, order) => {
    if (search) {
        const obj = JSON.parse(decodeURIComponent(search))
        let srt = ''

        for (let key in obj) {
            obj[key].forEach(el => {
                srt += `${srt && '&'}${key}=` + el
            });
        }

        return srt
    }
}

export const createQuerySecondType = (search, order_by, order) => {
    if (search) {
        const obj = JSON.parse(decodeURIComponent(search))
        let srt = ''

        for (let key in obj) {
            if(key === 'statuses') {
                obj[key].forEach(el => {
                    srt += `${srt && '&'}${key}=` + el
                })
            } else {
                // if (obj[key]) srt += `${srt && '&'}${key}=` + obj[key]
                srt = 'search=' + search
            }
        }
        
        return srt
    }
}


export const getQueryStringFromObject = (filter) => {
    let str = new URLSearchParams(filter).toString()
    return str.slice(0, -1);
}