export const distributionsStatus = {
  IN_QUEUE: {
    title: "InLine",
    bg: "#3949AB",
  },
  WAITING: {
    title: "Expectation",
    bg: "#FFC107",
  },
  CANCELED: {
    title: "NoAnswer",
    bg: "#EF6C00",
  },
  ACCEPTED: {
    title: "Accepted",
    bg: "#43A047",
  },
  REJECTED: {
    title: "Rejected",
    bg: "#D32F2F",
  },
  ON_REVISION: {
    title: "UnderDevelopment",
    bg: "#D32F2F",
  },
};

export const ordersStatus = {
  CREATED: {
    title: "SendForDistribution",
    bg: "#FFC107",
  },
  DISTRIBUTING: {
    title: "AtTheDistribution",
    bg: "#3949AB",
  },
  DISTIBUTING: {
    title: "AtTheDistribution",
    bg: "#3949AB",
  },
  IN_PROGRESS: {
    title: "InProgress",
    bg: "#43A047",
  },
  VENDOR_DONE: {
    title: "SubmittedForInspection",
    bg: "#43A047",
  },
  COMPLITED: {
    title: "Done",
    bg: "#43A047",
  },
  REJECTED: {
    title: "Deactivated",
    bg: "#D32F2F",
  },
  ON_REVISION: {
    title: "UnderDevelopment",
    bg: "#D32F2F",
  },
  STOPPED: {
    title: "Stopped",
    bg: "#D32F2F",
  },
  IN_SHIPPING: {
    title: "AwaitingShipment",
    bg: "#FFC107",
  },
};

export const operationStatus = {
  WAITING: {
    title: "InAnticipation",
    bg: "#FFC107",
  },
  SUCCEEDED: {
    title: "Done",
    bg: "#43A047",
  },
  CANCELLED: {
    title: "Cancelled",
    bg: "#D32F2F",
  },
  REJECTED: {
    title: "Rejectedo",
    bg: "#D32F2F",
  },
};
