import { Form, notification } from "antd";
import deleteIcon from "assets/images/icons/delete.svg";
import downloading from "assets/images/icons/downloading.svg";
import editPen from "assets/images/icons/edit_pen.svg";
import UpdateOrderPriceForm from "components/components/forms/updateOrder/UpdateOrderPriceForm";
import UpdateOrderCommentForm from "components/components/forms/updateOrderComment/UpdateOrderCommentForm";
import SimpleButton from "components/shared/buttons/simpleButton/SimpleButton";
import Selector from "components/shared/selector/Selector";
import Table from "components/shared/table/Table";
import InfoBlock from "components/ui/infoBlock/InfoBlock";
import { adminListOrderStatuses } from "data/list";
import { ordersStatus } from "data/statuses";
import { documentsInOrderTable, productsInOrderTable } from "data/tableHeader";
import {
  documentsInOrderTableWidth,
  productsInOrderTableWidth,
} from "data/tableWidth";
import { leadToCurrencyWithSymbol } from "helpers";
import { createFio } from "hooks/createFio";
import { warningNotification } from "hooks/notifications";
import { useRef, useState } from "react";
import { connect } from "react-redux";
import { SET_VISIBLE_MODAL } from "store/actionTypes";
import {
  fetchAcceptVerificationUploadTrackFile,
  fetchOrderDeactivate,
  fetchOrderDistribute,
  fetchRemoveCodeFiles,
  fetchRemoveTrackFiles,
  fetchUpdateOrder,
} from "store/actions/admin/orders";
import { useTranslation } from "react-i18next";

const MainInfo = ({
  order_by_id_data,
  fetchOrderDistribute,
  fetchOrderDeactivate,
  fetchUpdateOrder,
  fetchAcceptVerificationUploadTrackFile,
  setVisible,
  fetchRemoveTrackFiles,
  fetchRemoveCodeFiles,
  activeCurrency,
}) => {
  const { i18n } = useTranslation();
  const [api, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();
  const [editPrice, setEditPrice] = useState(false);
  const [editCommissionPrice, setEditCommissionPrice] = useState(false);
  const [editAdditionalComment, setEditAdditionalComment] = useState(false);
  const fileInputRef = useRef();

  const createTableData = () => {
    return order_by_id_data.products.map((el) => [
      {
        type: "text",
        content: el.material || "-",
      },
      {
        type: "text",
        content: el.color || "-",
      },
      {
        type: "text",
        content: el.infill || "-",
      },
      {
        type: "text",
        content: el.printer || "-",
      },
      {
        type: "text",
        content: el.quantity || "-",
      },
      // {
      //     type: 'text',
      //     content: el.price || '-'
      // },
      {
        type: "actions",
        content: el.link_model !== "" && (
          <a href={el.link_model} download target="_blank">
            <img src={downloading} alt="downloading" />
          </a>
        ),
      },
    ]);
  };

  const createDocumentsTableData = () => {
    const docs = [];
    order_by_id_data.code_files.forEach((el) =>
      docs.push({
        type: "code_files",
        data: el,
      })
    );

    order_by_id_data.track_files.forEach((el) =>
      docs.push({
        type: "track_files",
        data: el,
      })
    );

    return docs.map((el) => [
      {
        type: "text",
        content: el.data.media.title || "-",
      },
      {
        type: "text",
        content:
          el.type === "code_files"
            ? i18n.t("Barcode")
            : i18n.t("ConsignmentNote"),
      },
      {
        type: "actions",
        content: (
          <div className="tableIconsBlock">
            <a href={el.data.media.link} download target="_blank">
              <img src={downloading} alt="downloading" />
            </a>
            <img
              src={deleteIcon}
              alt="deleteIcon"
              onClick={() =>
                warningNotification(
                  api,
                  async () => {
                    if (el.type === "code_files") {
                      fetchRemoveCodeFiles(order_by_id_data.id, [el.data.id]);
                    } else {
                      fetchRemoveTrackFiles(order_by_id_data.id, [el.data.id]);
                    }
                    api.destroy();
                  },
                  i18n.t("deletingDocument")
                )
              }
            />
          </div>
        ),
      },
    ]);
  };

  const editStatus = (newStatus) => {
    const i = adminListOrderStatuses
      .map((e) => i18n.t(e.title))
      .filter((el) => el.title === newStatus)[0].status;
    const data = {
      status: i,
    };
    fetchUpdateOrder(order_by_id_data.id, data);
  };

  const handleChangeFiles = async (event, finalFunction) => {
    let data = new FormData();
    data.append("track_file", event.target.files[0]);
    finalFunction(data);
  };

  return (
    <>
      {contextHolder}
      <InfoBlock
        style={{ marginTop: 24, marginBottom: 24, gap: 10 }}
        className="infoBlock twoRow"
      >
        <div style={{ width: "100%" }}>
          <p className="p12_400 mainText p_mb8">{i18n.t("Customer")}</p>
          <p className="p14_400 mainText p_mb16">
            {createFio(order_by_id_data.customer)}
          </p>
          <p className="p12_400 mainText p_mb8">{i18n.t("CustomersEmail")}</p>
          <p className="p14_400 mainText p_mb16">
            {order_by_id_data.customer.email || "-"}
          </p>
          <p className="p12_400 mainText p_mb8">
            {i18n.t("CustomersPhoneNumber")}
          </p>
          <p className="p14_400 mainText p_mb16">
            {order_by_id_data.customer.phone || "-"}
          </p>
          <p className="p12_400 mainText p_mb8">
            {i18n.t("AdditionalInformation")}
          </p>
          {editAdditionalComment ? (
            <UpdateOrderCommentForm
              initial={{
                additional_comment: order_by_id_data.additional_comment,
              }}
              onSave={() => setEditAdditionalComment(false)}
            />
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                gap: 10,
                width: "95%",
              }}
            >
              <p className="p14_400 mainText ">
                {order_by_id_data.additional_comment || "-"}
              </p>
              <img
                src={editPen}
                alt="editPen"
                width={15}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  if (!editPrice) {
                    setEditAdditionalComment(true);
                  } else {
                    form.submit();
                  }
                }}
              />
            </div>
          )}
        </div>
        <div style={{ width: "100%" }}>
          <p className="p12_400 mainText p_mb8">{i18n.t("APrice")}</p>
          {editPrice ? (
            <UpdateOrderPriceForm
              form={form}
              name={"price"}
              initial={{
                price: order_by_id_data.price,
              }}
              onSave={() => setEditPrice(false)}
            />
          ) : (
            <div
              className="p_mb16"
              style={{ display: "flex", alignItems: "center", gap: 10 }}
            >
              <p className="p14_400 mainText ">
                {leadToCurrencyWithSymbol(
                  activeCurrency,
                  order_by_id_data.price
                ) || "-"}
              </p>
              {/* {(order_by_id_data.status === 'DISTIBUTING'|| order_by_id_data.status === "CREATED") && */}
              <img
                src={editPen}
                alt="editPen"
                width={15}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  if (!editPrice) {
                    setEditPrice(true);
                  } else {
                    form.submit();
                  }
                }}
              />
              {/* } */}
            </div>
          )}

          <p className="p12_400 mainText p_mb8">
            {i18n.t("PriceForPerformer")}
          </p>
          {editCommissionPrice ? (
            <UpdateOrderPriceForm
              form={form}
              initial={{
                commission_price: order_by_id_data.commission_price,
              }}
              onSave={() => setEditCommissionPrice(false)}
              name={"commission_price"}
              max={order_by_id_data.price}
            />
          ) : (
            <div
              className="p_mb16"
              style={{ display: "flex", alignItems: "center", gap: 10 }}
            >
              <p className="p14_400 mainText ">
                {order_by_id_data.commission_price
                  ? leadToCurrencyWithSymbol(
                      activeCurrency,
                      (
                        Math.round(order_by_id_data.commission_price * 10) / 10
                      ).toFixed(2)
                    )
                  : `0 ${activeCurrency.symbol}`}
              </p>
              {/* {(order_by_id_data.status === 'DISTIBUTING' || order_by_id_data.status === "CREATED") && */}
              <img
                src={editPen}
                alt="editPen"
                width={15}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  if (!editCommissionPrice) {
                    setEditCommissionPrice(true);
                  } else {
                    form.submit();
                  }
                }}
              />
              {/* } */}
            </div>
          )}
          <p className="p12_400 mainText p_mb8">
            {i18n.t("ExpectedExecutionTimeMin")}
          </p>
          <p className="p14_400 mainText p_mb16">
            {String(order_by_id_data.process_time) || "-"}
          </p>
          <p className="p12_400 mainText p_mb8">{i18n.t("Status")}</p>
          <Selector
            list={adminListOrderStatuses.map((e) => ({
              ...e,
              title: i18n.t(e.title),
            }))}
            activeItem={i18n.t(ordersStatus[order_by_id_data.status].title)}
            setActiveSelect={editStatus}
            style={{
              backgroundColor: ordersStatus[order_by_id_data.status].bg,
            }}
          />
          <p className="p12_400 mainText p_mb8 p_mt16">
            {i18n.t("TrackingNumber")}
          </p>
          <p className="p14_400 mainText">
            {order_by_id_data.track_num || "-"}
          </p>
        </div>
      </InfoBlock>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: 24,
          marginBottom: 12,
        }}
      >
        <p className="p18_500 mainText">{i18n.t("Documents")}</p>
        <SimpleButton
          title={i18n.t("AddDocument")}
          className="greenButton"
          onClick={() => setVisible({ id: order_by_id_data.id })}
        />
      </div>
      <Table
        header={documentsInOrderTable.map((e) => ({
          ...e,
          title: i18n.t(e.title),
        }))}
        width={documentsInOrderTableWidth}
        data={createDocumentsTableData()}
      />
      <p className="p18_500 mainText p_mb8 p_mt24">{i18n.t("Order")}</p>
      <Table
        header={productsInOrderTable.map((e) => ({
          ...e,
          title: i18n.t(e.title),
        }))}
        width={productsInOrderTableWidth}
        data={createTableData()}
      />
      <div className="twoButton" style={{ marginTop: 16 }}>
        {order_by_id_data.status === "CREATED" && (
          <div>
            <SimpleButton
              title={i18n.t("SendForDistribution")}
              className="blueButton"
              onClick={() =>
                warningNotification(
                  api,
                  async () => {
                    await fetchOrderDistribute(order_by_id_data.id);
                    api.destroy();
                  },
                  i18n.t("sendingOrderForDistribution")
                )
              }
            />
          </div>
        )}
        {order_by_id_data.status !== "COMPLITED" &&
          order_by_id_data.status !== "REJECTED" && (
            <div
              className={
                order_by_id_data.status === "CREATED" ? "twoButton_item" : ""
              }
            >
              <SimpleButton
                title={i18n.t("DeactivateOrder")}
                className="redButton"
                onClick={() =>
                  warningNotification(
                    api,
                    async () => {
                      await fetchOrderDeactivate(order_by_id_data.id);
                      api.destroy();
                    },
                    i18n.t("orderDeactivation")
                  )
                }
              />
            </div>
          )}
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {
    order_by_id_data: state.adminOrderById.order_by_id_data,
    activeCurrency: state.user.activeCurrency,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchOrderDistribute: (order_id) =>
      dispatch(fetchOrderDistribute(order_id)),
    fetchOrderDeactivate: (order_id) =>
      dispatch(fetchOrderDeactivate(order_id)),
    fetchUpdateOrder: (order_id, data) =>
      dispatch(fetchUpdateOrder(order_id, data)),
    fetchAcceptVerificationUploadTrackFile: (order_id, track_file) =>
      dispatch(fetchAcceptVerificationUploadTrackFile(order_id, track_file)),
    setVisible: (data) =>
      dispatch({
        type: SET_VISIBLE_MODAL,
        modal_type: "addDocumentsOnOrder",
        data,
      }),
    fetchRemoveTrackFiles: (order_id, data) =>
      dispatch(fetchRemoveTrackFiles(order_id, data)),
    fetchRemoveCodeFiles: (order_id, data) =>
      dispatch(fetchRemoveCodeFiles(order_id, data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MainInfo);
