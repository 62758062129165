import { ApiUrl, instance } from '../apiEnv';

export const api_language = {

    async CreateLanguage(language) {
        return await instance.post(`${ApiUrl}available_language`, language)
    },
    async GetLanguages() {
        return await instance.get(`${ApiUrl}available_language`)
    },
    async DeleteLanguage(language_id) {
        return await instance.delete(`${ApiUrl}available_language/${language_id}`)
    },
}