export const adminOrdersFilter = [
  {
    title: "Status",
    param: "statuses",
    childrens: [
      {
        title: "SendForDistribution",
        param: "CREATED",
      },
      {
        title: "AtTheDistribution",
        param: "DISTIBUTING",
      },
      {
        title: "InProgress",
        param: "IN_PROGRESS",
      },
      {
        title: "SubmittedForInspection",
        param: "VENDOR_DONE",
      },
      {
        title: "AwaitingShipment",
        param: "IN_SHIPPING",
      },
      {
        title: "Done",
        param: "COMPLITED",
      },
      {
        title: "Stopped",
        param: "STOPPED",
      },
      {
        title: "Deactivated",
        param: "REJECTED",
      },
      {
        title: "UnderDevelopment",
        param: "ON_REVISION",
      },
    ],
  },
];

export const userOrdersFilter = [
  {
    title: "Status",
    param: "statuses",
    childrens: [
      {
        title: "InProgress",
        param: "IN_PROGRESS",
      },
      {
        title: "SubmittedForInspection",
        param: "VENDOR_DONE",
      },
      {
        title: "Done",
        param: "COMPLITED",
      },
      {
        title: "Deactivated",
        param: "REJECTED",
      },
      {
        title: "UnderDevelopment",
        param: "ON_REVISION",
      },
    ],
  },
];

export const adminContractorsFilter = [
  {
    title: "Status",
    param: "status",
    childrens: [
      {
        title: "Activna",
        param: "open",
      },
      {
        title: "Completeda",
        param: "close",
      },
    ],
  },
];

export const adminFinanceFilter = [
  {
    title: "Status",
    param: "statuses",
    childrens: [
      {
        title: "InAnticipation",
        param: "WAITING",
      },
      {
        title: "Done",
        param: "SUCCEEDED",
      },
      // {
      //     title: 'Отменено',
      //     param: 'CANCELLED'
      // },
      {
        title: "Rejectedo",
        param: "REJECTED",
      },
    ],
  },
];
