import { Modal } from 'antd';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { CLOSE_MODAL } from 'store/actionTypes';

import './style.css';

function CustomModal({ type, setVisible, modalComponent }) {
    const [open, setOpen] = useState(false)

    useEffect(() => {
        if (type) {
            setOpen(true)
        } else {
            setOpen(false)
        }
    }, [type])

    return (
        <Modal
            title=""
            visible={open}
            onCancel={setVisible}
            footer={[]}
            className="customModal"
            closable={false}
        >
            {type && modalComponent}
        </Modal>
    );
}

function mapStateToProps(state) {
    return {
        visible: state.modal.visible,
        type: state.modal.type,
        modalComponent: state.modal.component
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setVisible: () => dispatch({ type: CLOSE_MODAL }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomModal);