export const adminOrdersBreadcrumb = [
  {
    title: "Orders",
    to: -1,
  },
];

export const userOrdersBreadcrumb = [
  {
    title: "Orders",
    to: -1,
  },
];

export const adminVendersBreadcrumb = [
  {
    title: "Performers",
    to: -1,
  },
];
