import { notification } from "antd";
import downloading from "assets/images/icons/downloading.svg";
import SimpleButton from "components/shared/buttons/simpleButton/SimpleButton";
import Table from "components/shared/table/Table";
import InfoBlock from "components/ui/infoBlock/InfoBlock";
import { ordersStatus } from "data/statuses";
import { documentsInOrderTable, productsInOrderTable } from "data/tableHeader";
import {
  documentsInOrderTableWidth,
  productsInOrderTableWidth,
} from "data/tableWidth";
import { leadToCurrencyWithSymbol } from "helpers";
import { confirmNotification } from "hooks/notifications";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { SET_VISIBLE_MODAL } from "store/actionTypes";
import {
  fetchAcceptOrderById,
  fetchRejectOrderById,
} from "store/actions/user/orders";
import { useTranslation } from "react-i18next";

const MainInfo = ({
  order_by_id_data,
  fetchAcceptOrderById,
  fetchRejectOrderById,
  setVisible,
  activeCurrency,
}) => {
  const { i18n } = useTranslation();
  const [api, contextHolder] = notification.useNotification();
  const { id } = useParams();
  const navigate = useNavigate();

  const createTableData = () => {
    return order_by_id_data.products.map((el) => [
      {
        type: "text",
        content: el.material || "-",
      },
      {
        type: "text",
        content: el.color || "-",
      },
      {
        type: "text",
        content: el.infill || "-",
      },
      {
        type: "text",
        content: el.printer || "-",
      },
      {
        type: "text",
        content: el.quantity || "-",
      },
      // {
      //     type: 'text',
      //     content: el.price || '-'
      // },
      {
        type: "actions",
        content: el.link_model !== "" && (
          <a href={el.link_model} download target="_blank">
            <img src={downloading} alt="downloading" />
          </a>
        ),
      },
    ]);
  };

  const createDocumentsTableData = () => {
    const docs = [];
    order_by_id_data.code_files.forEach((el) =>
      docs.push({
        type: "code_files",
        data: el,
      })
    );

    order_by_id_data.track_files.forEach((el) =>
      docs.push({
        type: "track_files",
        data: el,
      })
    );

    return docs.map((el) => [
      {
        type: "text",
        content: el.data.media.title || "-",
      },
      {
        type: "text",
        content:
          el.type === "code_files"
            ? i18n.t("Barcode")
            : i18n.t("ConsignmentNote"),
      },
      {
        type: "actions",
        content: (
          <div className="tableIconsBlock">
            <a href={el.data.media.link} download target="_blank">
              <img src={downloading} alt="downloading" />
            </a>
          </div>
        ),
      },
    ]);
  };

  return (
    <>
      {contextHolder}
      <InfoBlock
        style={{ marginTop: 24, marginBottom: 24, gap: 10 }}
        className="infoBlock twoRow"
      >
        <div style={{ width: "100%" }}>
          <p className="p12_400 mainText p_mb8">Цена</p>
          <p className="p14_400 mainText p_mb16">
            {leadToCurrencyWithSymbol(
              activeCurrency,
              (Math.round(order_by_id_data.commission_price * 10) / 10).toFixed(
                1
              )
            ) || "-"}
          </p>
          <p className="p12_400 mainText p_mb8">
            {i18n.t("ExpectedExecutionTimeMin")}
          </p>
          <p className="p14_400 mainText p_mb16">
            {String(order_by_id_data.process_time) || "-"}
          </p>
          <p className="p12_400 mainText p_mb8">
            {i18n.t("AdditionalInformation")}
          </p>
          <p className="p14_400 mainText">
            {order_by_id_data.additional_comment || "-"}
          </p>
        </div>
        <div style={{ width: "100%" }}>
          {/* <p className='p12_400 mainText p_mb8'>Ожидаемая длительность исполнения, мин</p>
                    <p className='p14_400 mainText p_mb16'>{String(order_by_id_data.process_time) || '-'}</p> */}
          <p className="p12_400 mainText p_mb8">{i18n.t("Status")}</p>
          <p className="p14_400 mainText p_mb16">
            <div
              className="statusOnTable p12_400"
              style={{
                backgroundColor: ordersStatus[order_by_id_data.status].bg,
              }}
            >
              {i18n.t(ordersStatus[order_by_id_data.status].title)}
            </div>
          </p>
          <p className="p12_400 mainText p_mb8">{i18n.t("TrackingNumber")}</p>
          <p className="p14_400 mainText">
            {order_by_id_data.track_num || "-"}
          </p>
        </div>
      </InfoBlock>
      {/* <InfoBlock
                style={{ marginTop: 24, marginBottom: 24 }}
                className="infoBlock twoRow"
            >
                <div style={{ width: '100%' }}>
                    <p className='p12_400 mainText p_mb8'>Трек-номер</p>
                    <p className='p14_400 mainText'>{order_by_id_data.track_num || '-'}</p>
                </div>
                <div style={{ width: '100%' }}>
                    <p className='p12_400 mainText p_mb8'>Файл накладной</p>
                    <p className='p14_400 mainText'>{order_by_id_data.track_file ? <a href={order_by_id_data.track_file.media.link} download target="_blank"><img src={downloading} alt="downloading" /></a> : '-'}</p>
                </div>
            </InfoBlock> */}
      {order_by_id_data.code_files && order_by_id_data.track_files && (
        <div className="p_mb16">
          <p className="p18_500 mainText p_mb8">{i18n.t("Documents")}</p>
          <Table
            header={documentsInOrderTable.map((e) => ({
              ...e,
              title: i18n.t(e.title),
            }))}
            width={documentsInOrderTableWidth}
            data={createDocumentsTableData()}
          />
        </div>
      )}
      <p className="p18_500 mainText p_mb8">{i18n.t("Order")}</p>
      <Table
        header={productsInOrderTable.map((e) => ({
          ...e,
          title: i18n.t(e.title),
        }))}
        width={productsInOrderTableWidth}
        data={createTableData()}
      />
      {order_by_id_data.status === "VENDOR_DONE" && (
        <div className="twoButton" style={{ marginTop: 16 }}>
          <div>
            <SimpleButton
              title={i18n.t("TheOrderHasBeenSentForVerification")}
              className="whiteButton"
            />
          </div>
        </div>
      )}
      {(order_by_id_data.status === "CREATED" ||
        order_by_id_data.status === "DISTIBUTING") && (
        <div className="twoButton" style={{ marginTop: 16 }}>
          <div>
            <SimpleButton
              title={i18n.t("AcceptOrder")}
              className="blueButton"
              onClick={() =>
                confirmNotification(
                  api,
                  async () => {
                    await fetchAcceptOrderById(id).then((res) => {
                      if (res) navigate(-1);
                    });
                    api.destroy();
                  },
                  i18n.t("myСonsentToFulfillOrder")
                )
              }
            />
          </div>
          <div className="twoButton_item">
            <SimpleButton
              title={i18n.t("RefuseTheOffer")}
              className="redButton"
              onClick={() =>
                confirmNotification(
                  api,
                  async () => {
                    // await fetchRejectOrderById(id)
                    //     .then(res => {
                    //         if (res) navigate(-1)
                    //     })
                    api.destroy();
                    setVisible("orderReject", {
                      order_id: order_by_id_data.id,
                    });
                  },
                  i18n.t("refusalOfOffer")
                )
              }
            />
          </div>
        </div>
      )}
    </>
  );
};

function mapStateToProps(state) {
  return {
    order_by_id_data: state.orderById.order_by_id_data,
    activeCurrency: state.user.activeCurrency,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchAcceptOrderById: (order_id) =>
      dispatch(fetchAcceptOrderById(order_id)),
    fetchRejectOrderById: (order_id) =>
      dispatch(fetchRejectOrderById(order_id)),
    setVisible: (modal_type, data) =>
      dispatch({ type: SET_VISIBLE_MODAL, modal_type, data }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MainInfo);
