import { initToken } from "api/apiEnv";
import { api_login } from "api/login";
import { api_user } from "api/user/user";
import { errorNotification, successNotification } from "hooks/notifications";
import {
  EXIT,
  FETCH_REFRESH_TOKEN_SUCCESS,
  FETCH_USER_DATA_START,
  FETCH_USER_DATA_SUCCESS,
} from "../../actionTypes";
import { fetchStart, fetchSuccess } from "../fetchStatuses";

export function fetchMyData() {
  return async (dispatch) => {
    dispatch(fetchStart(FETCH_USER_DATA_START));
    try {
      const user_data = await api_user.GetUserMe();
      dispatch(
        fetchSuccess(FETCH_USER_DATA_SUCCESS, {
          user_data: user_data.data.data,
        })
      );
    } catch (e) {
      if (e.response.data.detail === "Could not validate credentials") {
        try {
          const new_token = await api_login.RefreshToken({
            refresh_token: localStorage.getItem(
              "studia3d-agregator-refresh-token"
            ),
          });
          dispatch(
            fetchSuccess(FETCH_REFRESH_TOKEN_SUCCESS, {
              access_token: new_token.data.data.access_token,
            })
          );
          initToken();
        } catch (e) {
          if (e.response.data.detail === "Refresh token invalid") {
            dispatch(fetchSuccess(EXIT));
          }
        }
      }
    }
  };
}

export function fetchUpdateMyData(data) {
  return async (dispatch) => {
    try {
      await api_user.PutUpdateUserMe(data);
      dispatch(fetchMyData());
      successNotification("DataUpdated", "DataUpdatedSuccessfully");
    } catch (e) {
      errorNotification("AnErrorOccurred");
    }
  };
}

export function fetchTogglePrinters(printer_id) {
  return async (dispatch) => {
    try {
      await api_user.PatchTogglePrinters(printer_id);
      // dispatch(fetchMyData())
    } catch (e) {
      errorNotification("AnErrorOccurred");
    }
  };
}

export function fetchToggleMaterials(material_id) {
  return async (dispatch) => {
    try {
      await api_user.PatchToggleMaterials(material_id);
      // dispatch(fetchMyData())
    } catch (e) {
      errorNotification("AnErrorOccurred");
    }
  };
}

export function updateLanguage(data) {
  return async (dispatch) => {
    try {
      await api_user.UpdateLanguage(data);
      dispatch(fetchMyData());
      successNotification("LanguageUpdated", "LanguageSuccessfullyUpdated");
    } catch (e) {
      errorNotification("AnErrorOccurred");
    }
  };
}

export function updateCurrency(data) {
  return async (dispatch) => {
    try {
      await api_user.UpdateCurrency(data);
      dispatch(fetchMyData());
      successNotification("CurrencyUpdated", "CurrencySuccessfullyUpdated");
    } catch (e) {
      errorNotification("AnErrorOccurred");
    }
  };
}
