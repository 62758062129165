import { Form, Select } from "antd";
import { useState } from "react";
import { connect } from "react-redux";
import { fetchSetExecutor } from "store/actions/admin/orders";
import { useTranslation } from "react-i18next";

const AssignPerformer = ({
  form,
  defData,
  order_id,
  onFinishFunc,
  venders,
  fetchSetExecutor,
}) => {
  const { i18n } = useTranslation();
  const [togglePrinters, setTogglePrinters] = useState([]);
  const [fields, setFields] = useState([
    {
      name: "vender_id",
      value: defData,
    },
  ]);

  const onFinish = async ({ vender_id }) => {
    await fetchSetExecutor(order_id, vender_id);
    onFinishFunc();
  };

  const onSelect = (printer_id) => {};

  const onDeselect = (printer_id) => {
    const arr = [...togglePrinters];
    let index = togglePrinters.indexOf(printer_id);
    if (index > -1) {
      arr.splice(index, 1);
      setTogglePrinters(arr);
    } else {
      setTogglePrinters([...togglePrinters, printer_id]);
    }
  };

  return (
    <Form
      layout="vertical"
      requiredMark={false}
      autoComplete="off"
      className="twoColumnForm"
      fields={fields}
      onFieldsChange={(_, allFields) => {
        setFields(allFields);
      }}
      form={form}
      onFinish={onFinish}
    >
      <Form.Item name="vender_id" style={{ width: "100%" }}>
        <Select
          placeholder={i18n.t("SelectPerformer")}
          style={{ width: "100%" }}
          onSelect={(e) => onSelect(e)}
          onDeselect={(e) => onDeselect(e)}
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          showSearch
          options={venders.map((el) => ({
            value: el.id,
            label: el.email + " - " + el.first_name + " " + el.last_name,
          }))}
        />
      </Form.Item>
    </Form>
  );
};

function mapStateToProps(state) {
  return {
    venders: state.venders.venders,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchSetExecutor: (order_id, executor_id) =>
      dispatch(fetchSetExecutor(order_id, executor_id)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AssignPerformer);
