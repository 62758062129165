import { Link } from 'react-router-dom';

import angleRightBlack from 'assets/images/icons/angle-right-black.svg';

import './style.css';

function Breadcrumb({ list, activeLink }) {
    return (
        <div className='breadcrumb'>
            {list.map((item, index) => (
                <div key={`breadcrumb_${index}`}>
                    <Link to={item.to} className="p14_400">{item.title}</Link>
                    <img src={angleRightBlack} alt="angleRightBlack" />
                </div>
            ))}
            <p className='activeText p14_400'>{activeLink}</p>
        </div>
    );
}

export default Breadcrumb;