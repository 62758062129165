import Pagination from "components/shared/pagination/Pagination";
import Table from "components/shared/table/Table";
import TextHeading from "components/ui/teaxtHeading/TextHeading";
import { ordersStatus } from "data/statuses";
import { allUserOffersTable } from "data/tableHeader";
import { allUserOffersTableWidth } from "data/tableWidth";
import { createQuery } from "hooks/createQuery";
import { formatDateTime } from "hooks/formatDate";
import { warningNotificationWithoutConfirmation } from "hooks/notifications";
import { useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { fetchAllOrders } from "store/actions/user/orders";
import { useTranslation } from "react-i18next";

const AllOffers = ({ orders, pages, queue_places, fetchAllOrders }) => {
  const { i18n } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const query = Object.fromEntries([...searchParams]);
    if (query.page !== undefined) {
      fetchAllOrders(
        query.page,
        50,
        createQuery(
          Object.fromEntries([...searchParams]).search,
          Object.fromEntries([...searchParams]).order_by,
          Object.fromEntries([...searchParams]).order
        )
      );
    } else {
      query.page = 1;
      setSearchParams(query);
    }
  }, [
    Object.fromEntries([...searchParams]).page,
    Object.fromEntries([...searchParams]).search,
    Object.fromEntries([...searchParams]).order_by,
    Object.fromEntries([...searchParams]).order,
  ]);

  const createTableData = () => {
    return orders.map((el, key) => [
      {
        type: "active_text",
        content: (
          <span
            onClick={() => {
              if (queue_places[key][el.id] === 0) {
                navigate(`/user/my-offers/${el.id}`);
              } else {
                warningNotificationWithoutConfirmation(
                  i18n.t(
                    "OpeningOfferWillBeAvailableWhenItIsYourTurnForThisOrder"
                  )
                );
              }
            }}
          >
            {el.id}
          </span>
        ),
      },
      {
        type: "text",
        content: formatDateTime(el.time_created),
      },
      // {
      //     type: 'text',
      //     content: el.commission_price | '-'
      // },
      {
        type: "text",
        content: queue_places[key][el.id] + 1,
      },
      {
        type: "text",
        content: el.process_time,
      },
      {
        type: "actions",
        content: (
          <div
            className="statusOnTable p12_400"
            style={{ backgroundColor: ordersStatus[el.status].bg }}
          >
            {i18n.t(ordersStatus[el.status].title)}
          </div>
        ),
      },
    ]);
  };

  return (
    <div className="contentBlock">
      <div className="contentBlock_header">
        <div className="contentBlock_header__leftHalf">
          <TextHeading text={i18n.t("Offers")} />
        </div>
        <div className="contentBlock_header__rightHalf">
          {/* <Filter
                        className={'filter_marginRight'}
                        filterList={adminOrdersFilter.map((e) => ({...e,title:i18n.t(e.title)}))}
                    /> */}
        </div>
      </div>
      <Table
        header={allUserOffersTable.map((e) => ({
          ...e,
          title: i18n.t(e.title),
        }))}
        width={allUserOffersTableWidth}
        data={createTableData()}
      />
      {orders.length > 0 && <Pagination pages={pages} />}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    orders: state.orders.orders,
    queue_places: state.orders.queue_places,
    pages: state.orders.pages,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchAllOrders: (page, size, params) =>
      dispatch(fetchAllOrders(page, size, params)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AllOffers);
