import {
    FETCH_ALL_LANGUAGES_START,
    FETCH_ALL_LANGUAGES_SUCCESS,
    FETCH_SEND_LANGUAGE_START,
    FETCH_SEND_LANGUAGE_SUCCESS
} from '../../actionTypes'

const initialState = {
    languages: [],
    loading: false,
    fetch: false,
}

export default function initialReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_ALL_LANGUAGES_START:
            return {
                ...state, loading: true
            }
        case FETCH_ALL_LANGUAGES_SUCCESS:
            return {
                languages: action.data.data,
                loading: false
            }
        case FETCH_SEND_LANGUAGE_START:
            return {
                ...state, fetch: true
            }
        case FETCH_SEND_LANGUAGE_SUCCESS:
            return {
                ...state,
                languages: action.data.data,
                fetch: false
            }
        default: return state
    }
}