import CreateOperationForm from "components/components/forms/modal/createOperation/CreateOperationForm";
import { leadToCurrencyWithSymbol } from "helpers";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

const CreateOperation = ({ user_data, activeCurrency }) => {
  const { i18n } = useTranslation();
  return (
    <div>
      <p
        className="mainText p24_600 p_mb8"
        style={{ textAlign: "center", width: "30vw" }}
      >
        {i18n.t("CreateAnOperation")}
      </p>
      <p className="subtext p_mb8" style={{ textAlign: "center" }}>
        {i18n.t("BeforeCreatingAnOperationEnterTheAmountOfTheOperation")}
      </p>
      <p className="p16_500 p_mb4" style={{ textAlign: "center" }}>
        {i18n.t("YourBalance")}
        {leadToCurrencyWithSymbol(
          activeCurrency,
          Math.floor(user_data.balance).toFixed(0)
        )}
      </p>
      <p className="p12_400 p_mb16" style={{ textAlign: "center" }}>
        ({i18n.t("minimumWithdrawalAmount")}
        {leadToCurrencyWithSymbol(activeCurrency, 1000)})
      </p>
      <CreateOperationForm />
    </div>
  );
};

function mapStateToProps(state) {
  return {
    user_data: state.user.user_data,
    activeCurrency: state.user.activeCurrency,
  };
}

export default connect(mapStateToProps, null)(CreateOperation);
