import { Form, Input, Select, notification } from "antd";
import PhoneInput from "antd-phone-input";
import editPen from "assets/images/icons/edit_pen.svg";
import { successNotification, warningNotification } from "hooks/notifications";
import { useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import {
  fetchChangePassword,
  fetchToggleMaterials,
  fetchTogglePrinters,
  fetchUpdateUser,
} from "store/actions/admin/users";
import { useTranslation } from "react-i18next";

function EditMainInfoForm({
  form,
  initial,
  onSave,
  coords,
  user_by_id_data,
  materials,
  printers,
  fetchUpdateUser,
  fetchTogglePrinters,
  fetchToggleMaterials,
  fetchChangePassword,
}) {
  const { i18n } = useTranslation();
  const [editPassword, setEditPassword] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const { id } = useParams();

  const onFinish = async (values) => {
    const initMaterials = user_by_id_data.materials.map((el) => el.id);
    const deleteMaterials = initMaterials?.filter(
      (el) => values.materials_id.indexOf(el) < 0
    );
    const addMaterials = values?.materials_id?.filter(
      (el) => initMaterials?.indexOf(el) < 0
    );
    const editMaterials = deleteMaterials.concat(addMaterials);

    const initPrinters = user_by_id_data.printers.map((el) => el.id);
    const deletePrinters = initPrinters?.filter(
      (el) => values.printers_id.indexOf(el) < 0
    );
    const addPrinters = values?.printers_id?.filter(
      (el) => initPrinters?.indexOf(el) < 0
    );
    const editPrinters = deletePrinters.concat(addPrinters);

    for (let i = 0; i < editMaterials.length; i++) {
      await fetchToggleMaterials(id, editMaterials[i]);
    }

    for (let i = 0; i < editPrinters.length; i++) {
      await fetchTogglePrinters(id, editPrinters[i]);
    }

    if (editPassword) {
      fetchChangePassword(values.password, id);
    }
    values.phone = `${values.phone.countryCode}${values.phone.areaCode}${values.phone.phoneNumber}`;
    delete values.password;
    delete values.printers_id;
    delete values.materials_id;
    if (coords) {
      values.latitude = coords[0];
      values.longitude = coords[1];
    }

    fetchUpdateUser(id, values).then((res) => {
      if (res)
        successNotification(
          i18n.t("ArtistEdited"),
          i18n.t("TheArtistHasBeenSuccessfullyEdited")
        );
    });
    onSave();
  };

  const validator = (_, { valid }) => {
    if (valid()) return Promise.resolve();
    return Promise.reject(i18n.t("IncorrectNumber"));
  };

  return (
    <>
      {contextHolder}
      <Form
        layout="vertical"
        requiredMark={false}
        initialValues={initial}
        form={form}
        onFinish={onFinish}
        autoComplete="off"
        className="modalForm"
      >
        <Form.Item
          label={i18n.t("Name")}
          name="first_name"
          rules={[
            {
              required: true,
              message: i18n.t("ThisRequiredField"),
            },
          ]}
        >
          <Input placeholder={i18n.t("EnterName")} />
        </Form.Item>
        <Form.Item
          label={i18n.t("LastName")}
          name="last_name"
          rules={[
            {
              required: true,
              message: i18n.t("ThisRequiredField"),
            },
          ]}
        >
          <Input placeholder={i18n.t("EnterLastName")} />
        </Form.Item>
        <Form.Item
          label={i18n.t("PhoneNumber")}
          name="phone"
          rules={[{ validator }]}
        >
          <PhoneInput enableSearch />
        </Form.Item>
        <Form.Item
          label={i18n.t("Email")}
          name="email"
          rules={[
            {
              required: true,
              message: i18n.t("ThisRequiredField"),
            },
          ]}
        >
          <Input placeholder={i18n.t("EnterEmail")} />
        </Form.Item>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 12,
            width: "100%",
          }}
        >
          <Form.Item
            label={i18n.t("Password")}
            name="password"
            rules={[
              {
                required: true,
                message: i18n.t("ThisRequiredField"),
              },
            ]}
          >
            <Input.Password
              placeholder={i18n.t("EnterPassword")}
              disabled={!editPassword}
            />
          </Form.Item>
          {!editPassword && (
            <img
              src={editPen}
              alt="editPen"
              width={15}
              style={{ cursor: "pointer" }}
              onClick={() =>
                warningNotification(
                  api,
                  () => {
                    setEditPassword(true);
                    form.setFieldValue("password", "");
                    api.destroy();
                  },
                  i18n.t("changePassword")
                )
              }
            />
          )}
        </div>
        <Form.Item
          label={i18n.t("Address")}
          name="address"
          rules={[
            {
              required: true,
              message: i18n.t("ThisRequiredField"),
            },
          ]}
        >
          <Input placeholder={i18n.t("EnterAddress")} />
        </Form.Item>
        <Form.Item
          label={i18n.t("Commission")}
          name="commission"
          rules={[
            {
              required: true,
              message: i18n.t("ThisRequiredField"),
            },
          ]}
        >
          <Input placeholder={i18n.t("EnterCommission")} type="number" />
        </Form.Item>
        <Form.Item
          label={i18n.t("NameOfTheOrganization")}
          name="organization_name"
          rules={[
            {
              required: false,
              message: i18n.t("ThisRequiredField"),
            },
          ]}
        >
          <Input placeholder={i18n.t("EnterNameOfTheOrganization")} />
        </Form.Item>
        <Form.Item
          label={i18n.t("LegalAddress")}
          name="legal_address"
          rules={[
            {
              required: false,
              message: i18n.t("ThisRequiredField"),
            },
          ]}
        >
          <Input placeholder={i18n.t("EnterLegalAddress")} />
        </Form.Item>
        <Form.Item
          label={i18n.t("INN")}
          name="inn"
          rules={[
            {
              required: false,
              message: i18n.t("ThisRequiredField"),
            },
          ]}
        >
          <Input placeholder={i18n.t("EnterINN")} />
        </Form.Item>
        <Form.Item
          label={i18n.t("KPP")}
          name="kpp"
          rules={[
            {
              required: false,
              message: i18n.t("ThisRequiredField"),
            },
          ]}
        >
          <Input placeholder={i18n.t("EnterKPP")} />
        </Form.Item>
        <Form.Item
          label={i18n.t("OGRN")}
          name="ogrnip"
          rules={[
            {
              required: false,
              message: i18n.t("ThisRequiredField"),
            },
          ]}
        >
          <Input placeholder={i18n.t("EnterOGRN")} />
        </Form.Item>
        <Form.Item
          label={i18n.t("NameOfTheBank")}
          name="bank_name"
          rules={[
            {
              required: false,
              message: i18n.t("ThisRequiredField"),
            },
          ]}
        >
          <Input placeholder={i18n.t("EnterNameOfTheBank")} />
        </Form.Item>
        <Form.Item
          label={i18n.t("PaymentAccount")}
          name="bank_account"
          rules={[
            {
              required: false,
              message: i18n.t("ThisRequiredField"),
            },
          ]}
        >
          <Input placeholder={i18n.t("EnterPaymentAccount")} />
        </Form.Item>
        <Form.Item
          label={i18n.t("BICBank")}
          name="bic"
          rules={[
            {
              required: false,
              message: i18n.t("ThisRequiredField"),
            },
          ]}
        >
          <Input placeholder={i18n.t("EnterBICBank")} />
        </Form.Item>
        <Form.Item
          label={i18n.t("CorrespondentAccountOfTheBank")}
          name="correspondent_account"
          rules={[
            {
              required: false,
              message: i18n.t("ThisRequiredField"),
            },
          ]}
        >
          <Input placeholder={i18n.t("EnterCorrespondentAccountOfTheBank")} />
        </Form.Item>
        <Form.Item
          label={i18n.t("Materials")}
          name="materials_id"
          style={{ width: "100%" }}
        >
          <Select
            placeholder={i18n.t("SelectMaterial")}
            style={{ width: "100%" }}
            mode="multiple"
            // onSelect={(e) => onSelect(e)}
            // onDeselect={(e) => onDeselect(e)}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            options={materials.map((el) => ({
              value: el.id,
              label: el.value,
            }))}
          />
        </Form.Item>
        <Form.Item
          label={i18n.t("Printers")}
          name="printers_id"
          style={{ width: "100%" }}
        >
          <Select
            placeholder={i18n.t("SelectPrinters")}
            style={{ width: "100%" }}
            mode="multiple"
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            options={printers.map((el) => ({
              value: el.id,
              label: el.value,
            }))}
          />
        </Form.Item>
        <Form.Item
          label={i18n.t("Active")}
          name="is_active"
          style={{ width: "100%" }}
        >
          <Select
            placeholder={i18n.t("SelectActivityStatus")}
            style={{ width: "100%" }}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            options={[
              {
                value: true,
                label: i18n.t("Yes"),
              },
              {
                value: false,
                label: i18n.t("No"),
              },
            ]}
          />
        </Form.Item>
        <Form.Item
          label={i18n.t("Verified")}
          name="is_verified"
          style={{ width: "100%" }}
        >
          <Select
            placeholder={i18n.t("SelectVerificationStatus")}
            style={{ width: "100%" }}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            options={[
              {
                value: true,
                label: i18n.t("Yes"),
              },
              {
                value: false,
                label: i18n.t("No"),
              },
            ]}
          />
        </Form.Item>
      </Form>
    </>
  );
}

function mapStateToProps(state) {
  return {
    materials: state.materials.materials,
    printers: state.printers.printers,
    roles: state.roles.roles,
    user_by_id_data: state.adminUserById.user_by_id_data,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchUpdateUser: (user_id, user_data) =>
      dispatch(fetchUpdateUser(user_id, user_data)),
    fetchTogglePrinters: (user_id, printer_id) =>
      dispatch(fetchTogglePrinters(user_id, printer_id)),
    fetchToggleMaterials: (user_id, material_id) =>
      dispatch(fetchToggleMaterials(user_id, material_id)),
    fetchChangePassword: (password, user_id) =>
      dispatch(fetchChangePassword(password, user_id)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditMainInfoForm);
