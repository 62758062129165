import UserLayout from "components/layout/user/UserLayout";
import AllFinance from "views/user/finance/allFinance/AllFinance";
import AllOffers from "views/user/offers/allOffers/AllOffers";

import AllOrders from "views/user/orders/allOrders/AllOrders";
import OrderById from "views/user/orders/orderById/OrderById";
import Profile from "views/user/profile/Profile";

export const userRoutings = [
    {
        path: '/user/',
        element: <UserLayout />,
        navigate: '/user/my-profile',
        children: [
            {
                path: 'my-profile',
                index: false,
                element: <Profile />
            },
            {
                path: 'my-orders',
                index: false,
                element: <AllOrders />
            },
            {
                path: 'my-orders/:id',
                index: false,
                element: <OrderById />
            },
            {
                path: 'my-offers',
                index: false,
                element: <AllOffers />
            },
            {
                path: 'my-offers/:id',
                index: false,
                element: <OrderById />
            },
            {
                path: 'my-finance',
                index: false,
                element: <AllFinance />
            },
        ]
    },
]