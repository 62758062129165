export const adminOrderById = [
  {
    item: "mainInfo",
    title: "BasicInformation",
  },
  {
    item: "executors",
    title: "Performers",
  },
  {
    item: "verification",
    title: "Check",
  },
];

export const adminUserById = [
  {
    item: "mainInfo",
    title: "BasicInformation",
  },
];

export const userOrderById = [
  {
    item: "mainInfo",
    title: "BasicInformation",
  },
  {
    item: "verification",
    title: "Check",
  },
];

export const adminFinance = [
  {
    item: true,
    title: "Receipts",
  },
  {
    item: false,
    title: "WriteOffs",
  },
];
