import OrderCompleteForm from "components/components/forms/modal/orderComplete/OrderCompleteForm";
import { useTranslation } from "react-i18next";

const OrderComplete = () => {
  const { i18n } = useTranslation();
  return (
    <div style={{ maxWidth: 657 }}>
      <p className="mainText p24_600 p_mb8" style={{ textAlign: "center" }}>
        {i18n.t("TheOrderHasBeenCompleted")}
      </p>
      <p className="subtext p_mb16">
        {i18n.t(
          "ToSendAnOrderForVerificationYouNeedToAddPhotosOfTheCompletedWork"
        )}
      </p>
      <OrderCompleteForm />
    </div>
  );
};

export default OrderComplete;
