import { ApiUrl, instance } from '../apiEnv';

export const api_orders = {

    async GetOrders(page, size, params) {
        return await instance.get(`${ApiUrl}order?page=${page}&size=${size}&${params}`)
    },

    async GetOrderById(id) {
        return await instance.get(`${ApiUrl}order/${id}`)
    },

    async PostCreateOrder(data) {
        return await instance.post(`${ApiUrl}order`, data)
    },

    async PutUpdateOrder(order_id, data) {
        return await instance.put(`${ApiUrl}order/${order_id}`, data)
    },

    async PostOrderDeactivate(order_id) {
        return await instance.post(`${ApiUrl}order/${order_id}/admin/deactivate`)
    },

    async PostOrderDistribute(order_id) {
        return await instance.post(`${ApiUrl}order/${order_id}/distribute`)
    },

    async PostOfferExecutor(order_id, executor_id) {
        return await instance.post(`${ApiUrl}order/${order_id}/offer/${executor_id}`)
    },

    async PostRejectVerification(order_id, verification_id, comment, data) {
        return await instance.post(`${ApiUrl}order/${order_id}/admin/reject/${verification_id}?comment=${comment}`, data)
    },

    async PostAcceptVerification(order_id, verification_id, track_num) {
        return await instance.post(`${ApiUrl}order/${order_id}/admin/accept/${verification_id}?track_num=${track_num}`)
    },

    async PostAcceptVerificationUploadTrackFile(order_id, data) {
        return await instance.post(`${ApiUrl}order/${order_id}/admin/upload_track_file`, data)
    },

    async PostSetExecutor(order_id, executor_id) {
        return await instance.post(`${ApiUrl}order/${order_id}/set_executor/${executor_id}`)
    },

    async PostOrderDistributeWithData(order_id, data) {
        return await instance.post(`${ApiUrl}order/${order_id}/distribute`, data)
    },

    async PostOrderToggleStopped(order_id) {
        return await instance.post(`${ApiUrl}order/${order_id}/admin/toggle_stopped`)
    },

    async PutOrderUploadTrackFiles(order_id, data) {
        return await instance.put(`${ApiUrl}order/${order_id}/upload_track_files`, data)
    },

    async PutOrderRemoveTrackFiles(order_id, data) {
        return await instance.put(`${ApiUrl}order/${order_id}/remove_track_files`, data)
    },

    async PutOrderUploadCodeFiles(order_id, data) {
        return await instance.put(`${ApiUrl}order/${order_id}/upload_code_files`, data)
    },

    async PutOrderRemoveCodeFiles(order_id, data) {
        return await instance.put(`${ApiUrl}order/${order_id}/remove_code_files`, data)
    },
}