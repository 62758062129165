import { ApiUrl, instance } from '../apiEnv';

export const api_currency = {

    async CreateCurrency(currency) {
        return await instance.post(`${ApiUrl}currency`, currency)
    },
    async GetCurrencies() {
        return await instance.get(`${ApiUrl}currency`)
    },
    async UpdateCurrency(currency) {
        return await instance.put(`${ApiUrl}currency/${currency.id}`,currency)
    },
    async DeleteCurrency(currency_id) {
        return await instance.delete(`${ApiUrl}currency/${currency_id}`)
    },
}