import { Dropdown } from "antd";
import { useEffect, useState } from "react";

import angel_down_white from "assets/images/icons/angle-down-white.svg";

import "./style.css";

function Selector({ list, activeItem, setActiveSelect, ...props }) {
  const [activeSelector, setActiveSelector] = useState(false);
  const [items, setItems] = useState([]);

  useEffect(() => {
    const item = [];
    list.forEach((el, index) => {
      item.push({
        label: (
          <p
            className="mainText p12_400"
            onClick={() => {
              setActiveSelector(false);
              setActiveSelect(el.title);
            }}
          >
            {el.title}
          </p>
        ),
        key: index,
      });
    });
    setItems(item);
  }, [list]);

  return (
    <Dropdown
      menu={{ items }}
      trigger={["click"]}
      className="greenDropdown"
      onOpenChange={() => setActiveSelector(!activeSelector)}
      open={activeSelector}
      placement="bottomLeft"
    >
      <div className={`selector`} {...props}>
        <p className="p14_500">
          {list.filter((el) => el.title === activeItem)[0].title}
        </p>
        <img src={angel_down_white} alt="angleDownGreen" />
      </div>
    </Dropdown>
  );
}

export default Selector;
