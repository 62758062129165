import axios from "axios";
import { errorNotification, successNotification } from "hooks/notifications";
import { ApiUrl } from "./apiEnv";
import i18n from "i18next";

export const api_login = {
  async EmailLoginDeforeSignUp(data) {
    return axios.post(`${ApiUrl}login`, data, {
      headers: { "Content-Type": "application/json" },
    });
  },

  async Login(data, errorFunc) {
    return axios
      .post(`${ApiUrl}login`, data, {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        return res.data;
      })
      .catch(function (error) {
        if (error.response) {
          errorNotification(
            "IncorrectDataDuringAuthorization",
            "CheckYourLoginAndPassword"
          );
          errorFunc();
          return error.response.data.detail;
        } else {
          return i18n.t("ErrorNetworkError");
        }
      });
  },

  async PasswordRecovery(email) {
    return axios
      .post(`${ApiUrl}login/password_recovery/${email}`, {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        successNotification(
          "TheLetterHasBeenSent",
          "TheLetterHasBeenSuccessfullySentToTheSpecifiedEmail"
        );
        return res.data;
      })
      .catch(function (error) {
        if (error.response) {
          errorNotification("UserNotFound", "UserWithThisEmailNotFound");
          return error.response.data.detail;
        } else {
          return i18n.t("ErrorNetworkError");
        }
      });
  },

  async ResetPassword(data) {
    return axios.post(`${ApiUrl}login/reset_password`, data);
  },

  async RefreshToken(data) {
    return axios.post(`${ApiUrl}login/new_access_token`, data);
  },
};
