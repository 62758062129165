import { Checkbox, Dropdown } from "antd";
import { getQueryStringFromObject } from "hooks/createQuery";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import angleDownGreen from "assets/images/icons/angle-down-green.svg";
import angleUpGreen from "assets/images/icons/angle-up-green.svg";
import filter from "assets/images/icons/filter.svg";
import { useTranslation } from "react-i18next";
import "./style.css";

function Filter({ className, filterList, blockFilterList }) {
  const { i18n } = useTranslation();
  const [activeFilter, setActiveFilter] = useState(false);
  const [items, setItems] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [filterParams, setFilterParams] = useState({});

  useEffect(() => {
    const query = Object.fromEntries([...searchParams]);
    if (query.search) {
      setFilterParams(JSON.parse(decodeURIComponent(query.search)));
    } else {
      setFilterParams({});
    }
  }, [Object.fromEntries([...searchParams]).search]);

  useEffect(() => {
    const item = [];
    filterList.forEach((el, index) => {
      item.push({
        label: (
          <div>
            <p className="placeholder p12_400">{el.title}</p>
            <div className="filterParams">
              {el.childrens.map((children, i) => (
                <Checkbox
                  key={`children_${i}`}
                  checked={
                    filterParams[el.param]
                      ? filterParams[el.param].indexOf(children.param) > -1
                        ? true
                        : false
                      : false
                  }
                  onChange={(e) => onChange(e, el.param, children.param)}
                >
                  {i18n.t(children.title)}
                </Checkbox>
              ))}
            </div>
          </div>
        ),
        key: index,
      });
    });
    setItems(item);
  }, [filterList, filterParams]);

  const onChange = (e, el, children) => {
    const query = Object.fromEntries([...searchParams]);
    const obj = JSON.parse(JSON.stringify(filterParams));
    if (e.target.checked) {
      if (obj[el]) {
        obj[el].push(children);
      } else {
        obj[el] = [children];
      }
    } else {
      if (obj[el].length > 1) {
        const index = obj[el].indexOf(children);
        obj[el].splice(index, 1);
      } else {
        delete obj[el];
      }
    }
    query.search = getQueryStringFromObject(JSON.stringify(obj));
    setSearchParams(query);
    setFilterParams(obj);
  };

  const countLengthObj = () => {
    let count = 0;
    for (let key in filterParams) {
      if (blockFilterList) {
        if (blockFilterList.indexOf(key) === -1) count++;
      } else {
        count++;
      }
    }
    return count;
  };

  return (
    <Dropdown
      menu={{ items }}
      trigger={["click"]}
      className="greenDropdown"
      onOpenChange={() => setActiveFilter(!activeFilter)}
      open={activeFilter}
      placement="bottomRight"
    >
      <div className={`filter ${className}`}>
        <img src={filter} alt="filter" />
        <p className="activeText p14_500">{i18n.t("Filter")}</p>
        <img
          src={activeFilter ? angleUpGreen : angleDownGreen}
          alt="angleDownGreen"
          className="filter_angle"
        />
        {countLengthObj() > 0 && <div className="activeDot" />}
      </div>
    </Dropdown>
  );
}

export default Filter;
