import { modalChildrens } from "configs/modal/modal"
import { 
    SET_VISIBLE_MODAL,
    CLOSE_MODAL,
    CHANGE_MODAL_TYPE
 } from "store/actionTypes"

const initialState = {
    visible: false,
    type: null,
    component: null,
    data: null
}

export default function initialReducer(state = initialState, action) {
    switch (action.type) {
        case SET_VISIBLE_MODAL:
            return {
                ...state,
                visible: true,
                type: action.modal_type,
                component: modalChildrens[action.modal_type],
                data: action.data
            }
        case CLOSE_MODAL:
            return {
                ...state,
                visible: !state.visible,
                type: null,
                component: null,
                data: null
            }
        case CHANGE_MODAL_TYPE:
            return {
                ...state,
                type: action.payload
            }
        default: return state
    }
}