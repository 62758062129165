import { api_distribution } from "api/admin/distribution";
import { errorNotification, successNotification } from "hooks/notifications";
import { fetchOrderById } from "./orders";

export function fetchCreateDistribution(data) {
  return async (dispatch) => {
    try {
      await api_distribution.CreateDistribute(data);
      return true;
    } catch (e) {
      return false;
    }
  };
}

export function fetchDeleteDistribution(distribution_id, order_id) {
  return async (dispatch) => {
    try {
      await api_distribution.DeleteDistribute(distribution_id);
      successNotification(
        "ThePerformerHasBeenRemovedFromTheListOfCandidates",
        "ThePerformerHasBeenSuccessfullyRemovedFromTheListOfCandidates"
      );
      dispatch(fetchOrderById(order_id));
    } catch (e) {
      errorNotification("AnErrorOccurred");
    }
  };
}
