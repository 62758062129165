import { Form, Input } from "antd";
import InfoBlock from "components/ui/infoBlock/InfoBlock";
import { connect } from "react-redux";
import { fetchUpdateMyData } from "store/actions/user/user";
import { useTranslation } from "react-i18next";

function EditMainInfoForm({
  form,
  initial,
  user_data,
  coords,
  fetchUpdateMyData,
}) {
  const { i18n } = useTranslation();
  const onFinish = async (values) => {
    if (coords) {
      values.latitude = coords[0];
      values.longitude = coords[1];
    }
    fetchUpdateMyData({ ...user_data, ...values });
  };

  return (
    <InfoBlock style={{ marginTop: 24 }}>
      <Form
        layout="vertical"
        requiredMark={false}
        initialValues={initial}
        form={form}
        onFinish={onFinish}
        autoComplete="off"
        className="modalForm"
      >
        <Form.Item
          label={i18n.t("NameOfTheOrganization")}
          name="organization_name"
          rules={[
            {
              required: true,
              message: i18n.t("ThisRequiredField"),
            },
          ]}
        >
          <Input placeholder={i18n.t("EnterNameOfTheOrganization")} />
        </Form.Item>
        <Form.Item
          label={i18n.t("LegalAddress")}
          name="legal_address"
          rules={[
            {
              required: true,
              message: i18n.t("ThisRequiredField"),
            },
          ]}
        >
          <Input placeholder={i18n.t("EnterLegalAddress")} />
        </Form.Item>
        <Form.Item
          label={i18n.t("INN")}
          name="inn"
          rules={[
            {
              required: true,
              message: i18n.t("ThisRequiredFieldAndContainsOnlyNumbers"),
              pattern: new RegExp("^[0-9]*$"),
            },
          ]}
        >
          <Input placeholder={i18n.t("EnterINN")} />
        </Form.Item>
        <Form.Item
          label={i18n.t("KPP")}
          name="kpp"
          rules={[
            {
              required: true,
              message: i18n.t("ThisRequiredField"),
            },
          ]}
        >
          <Input placeholder={i18n.t("EnterKPP")} />
        </Form.Item>
        <Form.Item
          label={i18n.t("OGRN")}
          name="ogrnip"
          rules={[
            {
              required: true,
              message: i18n.t("ThisRequiredFieldAndContainsOnlyNumbers"),
              pattern: new RegExp("^[0-9]*$"),
            },
          ]}
        >
          <Input placeholder={i18n.t("EnterOGRN")} />
        </Form.Item>
        <Form.Item
          label={i18n.t("NameOfTheBank")}
          name="bank_name"
          rules={[
            {
              required: false,
              message: i18n.t("ThisRequiredField"),
            },
          ]}
        >
          <Input placeholder={i18n.t("EnterNameOfTheBankNameOfTheBank")} />
        </Form.Item>
        <Form.Item
          label={i18n.t("PaymentAccount")}
          name="bank_account"
          rules={[
            {
              required: true,
              message: i18n.t("ThisRequiredField"),
            },
          ]}
        >
          <Input placeholder={i18n.t("EnterPaymentAccount")} />
        </Form.Item>
        <Form.Item
          label={i18n.t("BICBank")}
          name="bic"
          rules={[
            {
              required: true,
              message: i18n.t("ThisRequiredFieldAndContainsOnlyNumbers"),
              pattern: new RegExp("^[0-9]*$"),
            },
          ]}
        >
          <Input placeholder={i18n.t("EnterBICBank")} />
        </Form.Item>
        <Form.Item
          label={i18n.t("CorrespondentAccountOfTheBank")}
          name="correspondent_account"
          rules={[
            {
              required: true,
              message: i18n.t("ThisRequiredFieldAndContainsOnlyNumbers"),
              pattern: new RegExp("^[0-9]*$"),
            },
          ]}
        >
          <Input placeholder={i18n.t("EnterCorrespondentAccountOfTheBank")} />
        </Form.Item>
      </Form>
    </InfoBlock>
  );
}

function mapStateToProps(state) {
  return {
    user_data: state.user.user_data,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchUpdateMyData: (data) => dispatch(fetchUpdateMyData(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditMainInfoForm);
