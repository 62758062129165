import { api_orders } from "api/admin/orders";
import { errorNotification, successNotification } from "hooks/notifications";
import {
  ADMIN_FETCH_ORDER_BY_ID_DATA_START,
  ADMIN_FETCH_ORDER_BY_ID_DATA_SUCCESS,
  FETCH_ALL_ORDERS_START,
  FETCH_ALL_ORDERS_SUCCESS,
} from "../../actionTypes";
import { fetchStart, fetchSuccess } from "../fetchStatuses";

export function fetchAllOrders(page, size, params) {
  return async (dispatch) => {
    dispatch(fetchStart(FETCH_ALL_ORDERS_START));
    try {
      const orders = await api_orders.GetOrders(page, size, params);
      dispatch(
        fetchSuccess(FETCH_ALL_ORDERS_SUCCESS, {
          data: orders.data.data,
        })
      );
    } catch (e) {}
  };
}

export function fetchOrderById(id) {
  return async (dispatch) => {
    dispatch(fetchStart(ADMIN_FETCH_ORDER_BY_ID_DATA_START));
    try {
      const order_data = await api_orders.GetOrderById(id);
      dispatch(
        fetchSuccess(ADMIN_FETCH_ORDER_BY_ID_DATA_SUCCESS, {
          order_by_id_data: order_data.data.data,
        })
      );
    } catch (e) {}
  };
}

export function fetchCreateOrder(data) {
  return async (dispatch) => {
    try {
      await api_orders.PostCreateOrder(data);
      successNotification("OrderCreated", "OrderSuccessfullyCreated");
      return true;
    } catch (e) {
      if (e.response.data.detail === "Resource already exists") {
        errorNotification("AnErrorOccurredInvalidCustomerId");
        return false;
      }
      if (e.response.data.detail[0].msg === "value is not a valid uuid") {
        errorNotification("AnErrorOccurredInvalidCustomerIdFormat");
        return false;
      }
    }
  };
}

export function fetchUpdateOrder(order_id, data) {
  return async (dispatch) => {
    try {
      await api_orders.PutUpdateOrder(order_id, data);
      dispatch(fetchOrderById(order_id));
      successNotification("OrderUpdated", "OrderUpdatedSuccessfully");
      return true;
    } catch (e) {
      if (e.response.data.detail === "Resource already exists") {
        errorNotification("AnErrorOccurredInvalidCustomerId");
        return false;
      }
      if (e.response.data.detail[0].msg === "value is not a valid uuid") {
        errorNotification("AnErrorOccurredInvalidCustomerIdFormat");
        return false;
      }
    }
  };
}

export function fetchOrderDistribute(order_id) {
  return async (dispatch) => {
    try {
      await api_orders.PostOrderDistribute(order_id);
      successNotification(
        "TheOrderHasBeenSentForDistribution",
        "TheOrderHasBeenSuccessfullySentForDistribution"
      );
      dispatch(fetchOrderById(order_id));
    } catch (e) {
      errorNotification("AnErrorOccurred");
    }
  };
}

export function fetchOrderDeactivate(order_id) {
  return async (dispatch) => {
    try {
      await api_orders.PostOrderDeactivate(order_id);
      successNotification(
        "OrderDeactivated",
        "TheOrderHasBeenSuccessfullyDeactivated"
      );
      dispatch(fetchOrderById(order_id));
    } catch (e) {
      errorNotification("AnErrorOccurred");
    }
  };
}

export function fetchOfferExecutor(order_id, executor_id) {
  return async (dispatch) => {
    try {
      await api_orders.PostOfferExecutor(order_id, executor_id);
      successNotification("OfferSent", "OfferSentSuccessfully");
      dispatch(fetchOrderById(order_id));
    } catch (e) {
      errorNotification("AnErrorOccurred");
    }
  };
}

export function fetchRejectVerification(
  order_id,
  verification_id,
  comment,
  requestBody
) {
  return async (dispatch) => {
    try {
      await api_orders.PostRejectVerification(
        order_id,
        verification_id,
        comment,
        requestBody
      );
      successNotification("ExecutionRejected", "ExecutionSuccessfullyRejected");
      dispatch(fetchOrderById(order_id));
    } catch (e) {
      errorNotification("AnErrorOccurred");
    }
  };
}

export function fetchAcceptVerification(
  order_id,
  verification_id,
  track_num,
  track_files,
  code_files
) {
  return async (dispatch) => {
    try {
      await api_orders.PostAcceptVerification(
        order_id,
        verification_id,
        track_num
      );
      if (track_files) {
        await api_orders.PutOrderUploadTrackFiles(order_id, track_files);
      }
      if (code_files) {
        await api_orders.PutOrderUploadCodeFiles(order_id, code_files);
      }
      successNotification(
        "CompletionConfirmed",
        "ExecutionSuccessfullyConfirmed"
      );
      dispatch(fetchOrderById(order_id));
    } catch (e) {
      errorNotification("AnErrorOccurred");
    }
  };
}

export function fetchAcceptVerificationUploadTrackFile(order_id, track_file) {
  return async (dispatch) => {
    try {
      await api_orders.PostAcceptVerificationUploadTrackFile(
        order_id,
        track_file
      );
      successNotification("FileUpdated", "");
      dispatch(fetchOrderById(order_id));
    } catch (e) {
      errorNotification("AnErrorOccurred");
    }
  };
}

export function fetchSetExecutor(order_id, executor_id) {
  return async (dispatch) => {
    try {
      await api_orders.PostSetExecutor(order_id, executor_id);
      successNotification(
        "ThePerformerHasBeenNamed",
        "ThePerformerHasBeenSuccessfullyAssigned"
      );
      dispatch(fetchOrderById(order_id));
    } catch (e) {
      errorNotification("AnErrorOccurred");
    }
  };
}

export function fetchOrderDistributeWithData(order_id, data) {
  return async (dispatch) => {
    try {
      await api_orders.PostOrderDistributeWithData(order_id, data);
      successNotification(
        "DistributionHasBeenRestarted",
        "DistributionRestartedSuccessfully"
      );
      dispatch(fetchOrderById(order_id));
    } catch (e) {
      errorNotification("AnErrorOccurred");
    }
  };
}

export function fetchOrderToggleStopped(order_id) {
  return async (dispatch) => {
    try {
      await api_orders.PostOrderToggleStopped(order_id);
      successNotification(
        "OrderDistributionStatusChanged",
        "OrderDistributionStatusChangedSuccessfully"
      );
      dispatch(fetchOrderById(order_id));
    } catch (e) {
      errorNotification("AnErrorOccurred");
    }
  };
}

export function fetchUploadTrackFiles(order_id, data) {
  return async (dispatch) => {
    try {
      await api_orders.PutOrderUploadTrackFiles(order_id, data);
      successNotification("DocumentsAdded", "DocumentsAddedSuccessfully");
      dispatch(fetchOrderById(order_id));
      return true;
    } catch (e) {
      errorNotification("AnErrorOccurred");
      return false;
    }
  };
}

export function fetchRemoveTrackFiles(order_id, data) {
  return async (dispatch) => {
    try {
      await api_orders.PutOrderRemoveTrackFiles(order_id, data);
      successNotification(
        "DocumentDeleted",
        "TheDocumentHasBeenSuccessfullyDeleted"
      );
      dispatch(fetchOrderById(order_id));
      return true;
    } catch (e) {
      errorNotification("AnErrorOccurred");
      return false;
    }
  };
}

export function fetchUploadCodeFiles(order_id, data) {
  return async (dispatch) => {
    try {
      await api_orders.PutOrderUploadCodeFiles(order_id, data);
      successNotification("DocumentsAdded", "DocumentsAddedSuccessfully");
      dispatch(fetchOrderById(order_id));
      return true;
    } catch (e) {
      errorNotification("AnErrorOccurred");
      return false;
    }
  };
}

export function fetchRemoveCodeFiles(order_id, data) {
  return async (dispatch) => {
    try {
      await api_orders.PutOrderRemoveCodeFiles(order_id, data);
      successNotification(
        "DocumentDeleted",
        "TheDocumentHasBeenSuccessfullyDeleted"
      );
      dispatch(fetchOrderById(order_id));
      return true;
    } catch (e) {
      errorNotification("AnErrorOccurred");
      return false;
    }
  };
}
