import { Form, Input } from "antd";
import FormButton from "components/shared/buttons/formButton/FormButton";
import { connect } from "react-redux";
import { fetchPasswordRecovery } from "store/actions/login";
import { useTranslation } from "react-i18next";

function PasswordRecoveryForm({ loading, fetchPasswordRecovery }) {
  const { i18n } = useTranslation();
  const onFinish = (values) => {
    fetchPasswordRecovery(values.email);
  };

  return (
    <Form
      layout="vertical"
      requiredMark={false}
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      autoComplete="off"
      className="modalForm"
    >
      <Form.Item
        label={i18n.t("EmailFull")}
        name="email"
        rules={[
          {
            required: true,
            message: i18n.t("ThisRequiredField"),
          },
        ]}
      >
        <Input placeholder={i18n.t("EnterMail")} />
      </Form.Item>
      <Form.Item>
        <FormButton
          title={i18n.t("RecoverPassword")}
          style={{ width: "100%" }}
          htmlType="submit"
          loading={loading}
        />
      </Form.Item>
    </Form>
  );
}

function mapStateToProps(state) {
  return {
    loading: state.userEnv.loading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchPasswordRecovery: (email) => dispatch(fetchPasswordRecovery(email)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PasswordRecoveryForm);
