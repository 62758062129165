import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import './style.css';

function SimpleButton({ title, loading, spinColor, ...props }) {

    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: 14,
                color: spinColor || 'white',
            }}
            spin
        />
    );

    return (
        <button
            className="simpleButton whiteButtonText"
            {...props}
        >
            {loading ? <Spin indicator={antIcon} /> : title}
        </button>
    );
}

export default SimpleButton;