import { Form, Select } from "antd";
import { useState } from "react";
import { connect } from "react-redux";
import { fetchMyData, fetchTogglePrinters } from "store/actions/user/user";
import { useTranslation } from "react-i18next";

const EditPrinters = ({
  form,
  defData,
  printers,
  fetchTogglePrinters,
  fetchMyData,
}) => {
  const { i18n } = useTranslation();
  const [togglePrinters, setTogglePrinters] = useState([]);
  const [fields, setFields] = useState([
    {
      name: "printers_id",
      value: defData,
    },
  ]);

  const onFinish = async ({}) => {
    for (let i = 0; i < togglePrinters.length; i++) {
      await fetchTogglePrinters(togglePrinters[i]);
    }
    await fetchMyData();
    setTogglePrinters([]);
  };

  const onSelect = (printer_id) => {
    setTogglePrinters([...togglePrinters, printer_id]);
  };

  const onDeselect = (printer_id) => {
    const arr = [...togglePrinters];
    let index = togglePrinters.indexOf(printer_id);
    if (index > -1) {
      arr.splice(index, 1);
      setTogglePrinters(arr);
    } else {
      setTogglePrinters([...togglePrinters, printer_id]);
    }
  };

  return (
    <Form
      layout="vertical"
      requiredMark={false}
      autoComplete="off"
      className="twoColumnForm"
      fields={fields}
      onFieldsChange={(_, allFields) => {
        setFields(allFields);
      }}
      form={form}
      onFinish={onFinish}
    >
      <Form.Item name="printers_id" style={{ width: "100%" }}>
        <Select
          placeholder={i18n.t("SelectPrinters")}
          style={{ width: "100%" }}
          mode="multiple"
          onSelect={(e) => onSelect(e)}
          onDeselect={(e) => onDeselect(e)}
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          options={printers.map((el) => ({
            value: el.id,
            label: el.value,
          }))}
        />
      </Form.Item>
    </Form>
  );
};

function mapStateToProps(state) {
  return {
    printers: state.printers.printers,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchTogglePrinters: (printer_id) =>
      dispatch(fetchTogglePrinters(printer_id)),
    fetchMyData: () => dispatch(fetchMyData()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditPrinters);
