import {
  FullscreenControl,
  GeolocationControl,
  Map,
  Placemark,
  SearchControl,
  TypeSelector,
} from "@pbe/react-yandex-maps";
import { Form } from "antd";
import EditMaterials from "components/components/forms/profile/editMaterials/EditMaterials";
import EditPrinters from "components/components/forms/profile/editPrinters/EditPrinters";
import SimpleButton from "components/shared/buttons/simpleButton/SimpleButton";
import InfoBlock from "components/ui/infoBlock/InfoBlock";
import { createFio } from "hooks/createFio";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import EditMainInfoForm from "components/components/forms/profile/editMainInfo/EditMainInfoForm";
import "./style.css";

const Profile = ({ user_data }) => {
  const { i18n } = useTranslation();
  const [formPrinter] = Form.useForm();
  const [formMaterial] = Form.useForm();
  const [formMainIno] = Form.useForm();
  const [edit, setEdit] = useState(false);
  const [coords, setCoords] = useState(null);

  useEffect(() => {
    if (user_data.latitude && user_data.longitude) {
      setCoords([user_data.latitude, user_data.longitude]);
    }
  }, []);

  const saveUpdate = () => {
    formPrinter.submit();
    formMaterial.submit();
    formMainIno.submit();
    setEdit(!edit);
  };

  return (
    <div className="contentBlock">
      <div className="userProfile_header">
        <div className="userProfile_header__info">
          {user_data.image ? (
            <div
              className="userProfile_header__info__avatar__photo"
              style={{ backgroundImage: `url(${user_data.image.media.link})` }}
            />
          ) : (
            <div className="userProfile_header__info__avatar p14_500">
              {user_data.first_name[0].toUpperCase() +
                user_data.last_name[0].toUpperCase()}
            </div>
          )}
          <p className="p24_600 mainText" style={{ textAlign: "center" }}>
            {createFio(user_data)}
          </p>
        </div>
        {edit ? (
          <div className="twoButton" style={{ marginTop: 16 }}>
            <div>
              <SimpleButton
                title={i18n.t("Cancel")}
                className="whiteButton"
                onClick={() => setEdit(!edit)}
              />
            </div>
            <div className="twoButton_item">
              <SimpleButton
                title={i18n.t("Save")}
                className="blueButton"
                onClick={saveUpdate}
              />
            </div>
          </div>
        ) : (
          <SimpleButton
            title={i18n.t("Edit")}
            className="blueButton"
            onClick={() => setEdit(!edit)}
          />
        )}
      </div>
      <InfoBlock style={{ marginTop: 24 }} className="infoBlock twoRow">
        <div style={{ width: "100%" }}>
          <p className="p12_400 mainText p_mb8">{i18n.t("FullName")}</p>
          <p className="p14_400 mainText p_mb16">{createFio(user_data)}</p>
          <p className="p12_400 mainText p_mb8">{i18n.t("EmailFull")}</p>
          <p className="p14_400 mainText">{user_data.email || "-"}</p>
          {/* <p className='p12_400 mainText p_mb8'>Номер телефона</p>
        <p className='p14_400 mainText'>{user_data.phone ? '+' + user_data.phone : '-'}</p> */}
        </div>
        <div style={{ width: "100%" }}>
          <p className="p12_400 mainText p_mb8">{i18n.t("Active")}</p>
          <p className="p14_400 mainText p_mb16">
            {user_data.is_active ? i18n.t("Yes") : i18n.t("No")}
          </p>
          <p className="p12_400 mainText p_mb8">{i18n.t("Verified")}</p>
          <p className="p14_400 mainText">
            {user_data.is_verified ? i18n.t("Yes") : i18n.t("No")}
          </p>
          {/* <p className='p12_400 mainText p_mb8'>Номер телефона</p>
        <p className='p14_400 mainText'>{user_data.phone ? '+' + user_data.phone : '-'}</p> */}
        </div>
      </InfoBlock>
      {edit ? (
        <EditMainInfoForm
          initial={{
            organization_name: user_data.organization_name,
            legal_address: user_data.legal_address,
            inn: user_data.inn,
            kpp: user_data.kpp,
            ogrnip: user_data.ogrnip,
            bank_name: user_data.bank_name,
            bank_account: user_data.bank_account,
            bic: user_data.bic,
            correspondent_account: user_data.correspondent_account,
          }}
          form={formMainIno}
          coords={coords}
        />
      ) : (
        <InfoBlock style={{ marginTop: 24 }} className="infoBlock twoRow">
          <div style={{ width: "100%" }}>
            <p className="p12_400 mainText p_mb8">
              {i18n.t("NameOfTheOrganization")}
            </p>
            <p className="p14_400 mainText p_mb16">
              {user_data.organization_name || "-"}
            </p>
            <p className="p12_400 mainText p_mb8">{i18n.t("LegalAddress")}</p>
            <p className="p14_400 mainText p_mb16">
              {user_data.legal_address || "-"}
            </p>
            <p className="p12_400 mainText p_mb8">{i18n.t("INN")}</p>
            <p className="p14_400 mainText p_mb16">{user_data.inn || "-"}</p>
            <p className="p12_400 mainText p_mb8">{i18n.t("KPP")}</p>
            <p className="p14_400 mainText p_mb16">{user_data.kpp || "-"}</p>
            <p className="p12_400 mainText p_mb8">{i18n.t("OGRN")}</p>
            <p className="p14_400 mainText">{user_data.ogrnip || "-"}</p>
          </div>
          <div style={{ width: "100%" }}>
            <p className="p12_400 mainText p_mb8">{i18n.t("NameOfTheBank")}</p>
            <p className="p14_400 mainText p_mb16">
              {user_data.bank_name || "-"}
            </p>
            <p className="p12_400 mainText p_mb8">{i18n.t("PaymentAccount")}</p>
            <p className="p14_400 mainText p_mb16">
              {user_data.bank_account || "-"}
            </p>
            <p className="p12_400 mainText p_mb8">{i18n.t("BICBank")}</p>
            <p className="p14_400 mainText p_mb16">{user_data.bic || "-"}</p>
            <p className="p12_400 mainText p_mb8">
              {i18n.t("CorrespondentAccountOfTheBank")}
            </p>
            <p className="p14_400 mainText">
              {user_data.correspondent_account || "-"}
            </p>
          </div>
        </InfoBlock>
      )}
      <InfoBlock style={{ marginTop: 24 }}>
        <p className="p12_400 mainText p_mb8">{i18n.t("Printers")}</p>
        {edit ? (
          <EditPrinters
            form={formPrinter}
            defData={user_data.printers.map((printer) => printer.id)}
          />
        ) : (
          <p className="p14_400 mainText ">
            {user_data.printers.length > 0
              ? user_data.printers.map((printer, key) => {
                  if (key === user_data.printers.length - 1) {
                    return printer.value;
                  } else {
                    return printer.value + ", ";
                  }
                })
              : "-"}
          </p>
        )}
      </InfoBlock>
      <InfoBlock style={{ marginTop: 24 }}>
        <p className="p12_400 mainText p_mb8">{i18n.t("Materials")}</p>
        {edit ? (
          <EditMaterials
            form={formMaterial}
            defData={user_data.materials.map((material) => material.id)}
          />
        ) : (
          <p className="p14_400 mainText">
            {user_data.materials.length > 0
              ? user_data.materials.map((material, key) => {
                  if (key === user_data.materials.length - 1) {
                    return material.value;
                  } else {
                    return material.value + ", ";
                  }
                })
              : "-"}
          </p>
        )}
      </InfoBlock>
      <div className="locationBlock">
        <div className="locationBlock_map">
          <Map
            defaultState={{
              center:
                user_data.latitude && user_data.longitude
                  ? [user_data.latitude, user_data.longitude]
                  : [55.751574, 37.573856],
              zoom: 15,
              type: "yandex#hybrid",
            }}
            width="100%"
            height="100%"
            onClick={(e) => {
              if (edit) {
                setCoords(e.get("coords"));
              }
            }}
          >
            <FullscreenControl />
            <SearchControl options={{ float: "right" }} />
            <GeolocationControl options={{ float: "left" }} />
            <TypeSelector options={{ float: "right" }} />
            {user_data.latitude && user_data.longitude && (
              <Placemark geometry={[user_data.latitude, user_data.longitude]} />
            )}

            {!edit
              ? user_data.latitude &&
                user_data.longitude && (
                  <Placemark
                    geometry={[user_data.latitude, user_data.longitude]}
                  />
                )
              : coords && <Placemark geometry={coords} />}
          </Map>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    user_data: state.user.user_data,
  };
}

export default connect(mapStateToProps, null)(Profile);
