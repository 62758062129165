import AddDocumentsOnOrderForm from "components/components/forms/modal/addDocumentsOnOrder/AddDocumentsOnOrderForm";
import { useTranslation } from "react-i18next";

const AddDocumentsOnOrder = () => {
  const { i18n } = useTranslation();
  return (
    <div style={{ maxWidth: 657 }}>
      <p className="mainText p24_600 p_mb8" style={{ textAlign: "center" }}>
        {i18n.t("AddDocument")}
      </p>
      <p className="subtext p_mb16">
        {i18n.t("BeforeAddingDocumentYouMustSelectDocumentType")}
      </p>
      <AddDocumentsOnOrderForm />
    </div>
  );
};

export default AddDocumentsOnOrder;
