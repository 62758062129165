import OrderDistributeForm from "components/components/forms/modal/orderDistribute/OrderDistributeForm";
import { useTranslation } from "react-i18next";

const OrderDistribute = () => {
  const { i18n } = useTranslation();
  return (
    <div style={{ maxWidth: "30vw" }}>
      <p
        className="mainText p24_600 p_mb8"
        style={{ textAlign: "center", width: "30vw" }}
      >
        {i18n.t("FormAQueue")}
      </p>
      <p className="subtext p_mb16" style={{ textAlign: "center" }}>
        {i18n.t("YouCanSelectPerformersToWhomYouDoNotWantToOfferAnOrder")}
      </p>
      <OrderDistributeForm />
    </div>
  );
};

export default OrderDistribute;
