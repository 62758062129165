import { Form, InputNumber } from "antd";
import SimpleButton from "components/shared/buttons/simpleButton/SimpleButton";
import {
  leadToCurrency,
  leadToCurrencyWithSymbol,
  leadToStandartCurrency,
} from "helpers";
import { createQuery } from "hooks/createQuery";
import { useState } from "react";
import { connect } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { CLOSE_MODAL } from "store/actionTypes";
import {
  fetchAllOperations,
  fetchCreateOperation,
} from "store/actions/user/operations";
import { useTranslation } from "react-i18next";

function CreateOperationForm({
  user_data,
  setVisible,
  fetchCreateOperation,
  fetchAllOperations,
  activeCurrency,
}) {
  const { i18n } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [fields, setFields] = useState([
    {
      name: "amount",
      value: "",
    },
  ]);

  const onFinish = async ({ amount }) => {
    amount = leadToStandartCurrency(activeCurrency, amount);
    const query = Object.fromEntries([...searchParams]);
    await fetchCreateOperation(amount);
    await fetchAllOperations(
      query.page,
      50,
      createQuery(
        Object.fromEntries([...searchParams]).search,
        Object.fromEntries([...searchParams]).order_by,
        Object.fromEntries([...searchParams]).order
      )
    );
    setVisible();
  };

  return (
    <Form
      layout="vertical"
      requiredMark={false}
      initialValues={{
        remember: true,
      }}
      fields={fields}
      onFieldsChange={(_, allFields) => {
        setFields(allFields);
      }}
      onFinish={onFinish}
      autoComplete="off"
      className="modalForm"
    >
      <Form.Item
        label={i18n.t("OperationAmount")}
        name="amount"
        rules={[
          {
            required: true,
            message: `${i18n.t("TheAmountShouldNotBeLess")} 
            ${leadToCurrencyWithSymbol(activeCurrency, 1000)} 
             ${i18n.t("andShouldNotBeMore")} 
             ${leadToCurrencyWithSymbol(
               activeCurrency,
               Math.floor(user_data.balance).toFixed(0)
             )}
             `,
          },
        ]}
        style={{ width: "100%" }}
      >
        <InputNumber
          placeholder={i18n.t("EnterTransactionAmount")}
          max={leadToCurrency(
            activeCurrency,
            Math.floor(user_data.balance).toFixed(0)
          )}
          min={leadToCurrency(activeCurrency, 1000)}
        />
      </Form.Item>
      <Form.Item style={{ marginBottom: 0 }}>
        <div>
          <SimpleButton
            title={i18n.t("Cancel")}
            className="activeText p14_500 whiteButton"
            style={{
              marginRight: 16,
            }}
            onClick={setVisible}
          />
          <SimpleButton
            title={i18n.t("CreateAnOperation")}
            className="p14_500 blueButton"
            htmltype="submit"
          />
        </div>
      </Form.Item>
    </Form>
  );
}

function mapStateToProps(state) {
  return {
    user_data: state.user.user_data,
    activeCurrency: state.user.activeCurrency,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setVisible: () => dispatch({ type: CLOSE_MODAL }),
    fetchCreateOperation: (amount) => dispatch(fetchCreateOperation(amount)),
    fetchAllOperations: (page, size, params) =>
      dispatch(fetchAllOperations(page, size, params)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateOperationForm);
