import {
  FullscreenControl,
  GeolocationControl,
  Map,
  Placemark,
  SearchControl,
  TypeSelector,
} from "@pbe/react-yandex-maps";
import { Form, notification } from "antd";
import EditMainInfoForm from "components/components/forms/editVender/EditMainInfoForm";
import InfoBlock from "components/ui/infoBlock/InfoBlock";
import { createFio } from "hooks/createFio";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

const MainInfo = ({ user_by_id_data, edit, save, onSave, onError }) => {
  const { i18n } = useTranslation();
  const [api, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();
  const [coords, setCoords] = useState(null);
  // const [edit, setEdit] = useState(false)

  useEffect(() => {
    if (user_by_id_data.latitude && user_by_id_data.longitude) {
      setCoords([user_by_id_data.latitude, user_by_id_data.longitude]);
    }
  }, [user_by_id_data]);

  useEffect(() => {
    if (save && edit) {
      form.submit();
    }
  }, [edit, save]);

  return (
    <>
      {contextHolder}
      {edit ? (
        <InfoBlock>
          <EditMainInfoForm
            initial={{
              first_name: user_by_id_data.first_name,
              last_name: user_by_id_data.last_name,
              phone: user_by_id_data.phone?.slice(2),
              email: user_by_id_data.email,
              password: "password",
              address: user_by_id_data.address,
              commission: user_by_id_data.commission,
              materials_id: user_by_id_data.materials.map((el) => el.id),
              printers_id: user_by_id_data.printers.map((el) => el.id),
              is_active: user_by_id_data.is_active,
              is_verified: user_by_id_data.is_verified,
              organization_name: user_by_id_data.organization_name,
              legal_address: user_by_id_data.legal_address,
              inn: user_by_id_data.inn,
              kpp: user_by_id_data.kpp,
              ogrnip: user_by_id_data.ogrnip,
              bank_name: user_by_id_data.bank_name,
              bank_account: user_by_id_data.bank_account,
              bic: user_by_id_data.bic,
              correspondent_account: user_by_id_data.correspondent_account,
            }}
            form={form}
            onSave={onSave}
            onError={onError}
            coords={coords}
          />
        </InfoBlock>
      ) : (
        <>
          <InfoBlock style={{ marginTop: 24 }} className="infoBlock twoRow">
            <div style={{ width: "100%" }}>
              <p className="p12_400 mainText p_mb8">{i18n.t("FullName")}</p>
              <p className="p14_400 mainText p_mb16">
                {createFio(user_by_id_data)}
              </p>
              <p className="p12_400 mainText p_mb8">{i18n.t("EmailFull")}</p>
              <p className="p14_400 mainText p_mb16">
                {user_by_id_data.email || "-"}
              </p>
              <p className="p12_400 mainText p_mb8">{i18n.t("PhoneNumber")}</p>
              <p className="p14_400 mainText p_mb16">
                {user_by_id_data.phone ? user_by_id_data.phone : "-"}
              </p>
              <p className="p12_400 mainText p_mb8">{i18n.t("Address")}</p>
              <p className="p14_400 mainText p_mb16">
                {user_by_id_data.address || "-"}
              </p>
              <p className="p12_400 mainText p_mb8">{i18n.t("Commission")}</p>
              <p className="p14_400 mainText">{user_by_id_data.commission}</p>
            </div>
            <div style={{ width: "100%" }}>
              <p className="p12_400 mainText p_mb8">{i18n.t("Active")}</p>
              <p className="p14_400 mainText p_mb16">
                {user_by_id_data.is_active ? i18n.t("Yes") : i18n.t("No")}
              </p>
              <p className="p12_400 mainText p_mb8">{i18n.t("Verified")}</p>
              <p className="p14_400 mainText">
                {user_by_id_data.is_verified ? i18n.t("Yes") : i18n.t("No")}
              </p>
            </div>
          </InfoBlock>
          <InfoBlock style={{ marginTop: 24 }} className="infoBlock twoRow">
            <div style={{ width: "100%" }}>
              <p className="p12_400 mainText p_mb8">
                {i18n.t("NameOfTheOrganization")}
              </p>
              <p className="p14_400 mainText p_mb16">
                {user_by_id_data.organization_name || "-"}
              </p>
              <p className="p12_400 mainText p_mb8">{i18n.t("LegalAddress")}</p>
              <p className="p14_400 mainText p_mb16">
                {user_by_id_data.legal_address || "-"}
              </p>
              <p className="p12_400 mainText p_mb8">{i18n.t("INN")}</p>
              <p className="p14_400 mainText p_mb16">
                {user_by_id_data.inn || "-"}
              </p>
              <p className="p12_400 mainText p_mb8">{i18n.t("KPP")}</p>
              <p className="p14_400 mainText p_mb16">
                {user_by_id_data.kpp || "-"}
              </p>
              <p className="p12_400 mainText p_mb8">{i18n.t("OGRN")}</p>
              <p className="p14_400 mainText">
                {user_by_id_data.ogrnip || "-"}
              </p>
            </div>
            <div style={{ width: "100%" }}>
              <p className="p12_400 mainText p_mb8">
                {i18n.t("NameOfTheBank")}
              </p>
              <p className="p14_400 mainText p_mb16">
                {user_by_id_data.bank_name || "-"}
              </p>
              <p className="p12_400 mainText p_mb8">
                {i18n.t("PaymentAccount")}
              </p>
              <p className="p14_400 mainText p_mb16">
                {user_by_id_data.bank_account || "-"}
              </p>
              <p className="p12_400 mainText p_mb8">{i18n.t("BICBank")}</p>
              <p className="p14_400 mainText p_mb16">
                {user_by_id_data.bic || "-"}
              </p>
              <p className="p12_400 mainText p_mb8">
                {i18n.t("CorrespondentAccountOfTheBank")}
              </p>
              <p className="p14_400 mainText">
                {user_by_id_data.correspondent_account || "-"}
              </p>
            </div>
          </InfoBlock>
          <InfoBlock style={{ marginTop: 24 }}>
            <p className="p12_400 mainText p_mb8">{i18n.t("Printers")}</p>
            <p className="p14_400 mainText ">
              {user_by_id_data.printers.length > 0
                ? user_by_id_data.printers.map((printer, key) => {
                    if (key === user_by_id_data.printers.length - 1) {
                      return printer.value;
                    } else {
                      return printer.value + ", ";
                    }
                  })
                : "-"}
            </p>
          </InfoBlock>
          <InfoBlock style={{ marginTop: 24 }}>
            <p className="p12_400 mainText p_mb8">{i18n.t("Materials")}</p>
            <p className="p14_400 mainText ">
              {user_by_id_data.materials.length > 0
                ? user_by_id_data.materials.map((material, key) => {
                    if (key === user_by_id_data.materials.length - 1) {
                      return material.value;
                    } else {
                      return material.value + ", ";
                    }
                  })
                : "-"}
            </p>
          </InfoBlock>
        </>
      )}
      <div className="locationBlock">
        <div className="locationBlock_map">
          <Map
            defaultState={{
              center:
                user_by_id_data.latitude && user_by_id_data.longitude
                  ? [user_by_id_data.latitude, user_by_id_data.longitude]
                  : [55.751574, 37.573856],
              zoom: 15,
              type: "yandex#hybrid",
            }}
            width="100%"
            height="100%"
            onClick={(e) => {
              if (edit) {
                setCoords(e.get("coords"));
              }
            }}
          >
            <FullscreenControl />
            <SearchControl options={{ float: "right" }} />
            <GeolocationControl options={{ float: "left" }} />
            <TypeSelector options={{ float: "right" }} />
            {user_by_id_data.latitude && user_by_id_data.longitude && (
              <Placemark
                geometry={[user_by_id_data.latitude, user_by_id_data.longitude]}
              />
            )}

            {!edit
              ? user_by_id_data.latitude &&
                user_by_id_data.longitude && (
                  <Placemark
                    geometry={[
                      user_by_id_data.latitude,
                      user_by_id_data.longitude,
                    ]}
                  />
                )
              : coords && <Placemark geometry={coords} />}
          </Map>
        </div>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {
    user_by_id_data: state.adminUserById.user_by_id_data,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(MainInfo);
