import { Form } from "antd";
import TextArea from "antd/es/input/TextArea";
import editPen from "assets/images/icons/edit_pen.svg";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchUpdateOrder } from "store/actions/admin/orders";
import { useTranslation } from "react-i18next";

function UpdateOrderCommentForm({ initial, onSave, fetchUpdateOrder }) {
  const { id } = useParams();
  const [form] = Form.useForm();
  const { i18n } = useTranslation();

  const onFinish = async (values) => {
    fetchUpdateOrder(id, values);
    onSave();
  };

  return (
    <Form
      layout="vertical"
      requiredMark={false}
      onFinish={onFinish}
      autoComplete="off"
      className="modalForm"
      initialValues={initial}
      form={form}
      style={{
        display: "flex",
        alignItems: "flex-start",
        gap: 10,
        marginBottom: 16,
      }}
    >
      <Form.Item
        name={"additional_comment"}
        rules={[
          {
            required: false,
            message: i18n.t("ThisRequiredField"),
          },
        ]}
        style={{
          marginBottom: 0,
          width: "95%",
        }}
      >
        <TextArea placeholder={i18n.t("EnterAdditionalInformation")} rows={4} />
      </Form.Item>
      <img
        src={editPen}
        alt="editPen"
        width={15}
        style={{ cursor: "pointer" }}
        onClick={() => {
          form.submit();
        }}
      />
    </Form>
  );
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    fetchUpdateOrder: (order_id, data) =>
      dispatch(fetchUpdateOrder(order_id, data)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateOrderCommentForm);
