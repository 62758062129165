import {
  FullscreenControl,
  GeolocationControl,
  Map,
  Placemark,
  SearchControl,
  TypeSelector,
} from "@pbe/react-yandex-maps";
import { Form, Input, Select } from "antd";
import PhoneInput from "antd-phone-input";
import FormButton from "components/shared/buttons/formButton/FormButton";
import { useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  fetchCreateUser,
  fetchToggleMaterials,
  fetchTogglePrinters,
} from "store/actions/admin/users";
import { useTranslation } from "react-i18next";

function CreateVenderForm({
  materials,
  printers,
  roles,
  fetchCreateUser,
  fetchTogglePrinters,
  fetchToggleMaterials,
}) {
  const { i18n } = useTranslation();
  const [coords, setCoords] = useState(null);
  const navigate = useNavigate();

  const onFinish = async (values) => {
    if (coords) {
      values.latitude = coords[0];
      values.longitude = coords[1];
    }
    values.is_superuser = false;
    values.is_verified = true;
    values.is_active = true;
    values.phone = `${values.phone.countryCode}${values.phone.areaCode}${values.phone.phoneNumber}`;
    values.role_id = roles.find((el) => el.name === "vendor").id;
    fetchCreateUser(values).then(async (res) => {
      if (res) {
        if (values.materials_id) {
          for (let i = 0; i < values.materials_id.length; i++) {
            await fetchToggleMaterials(res.id, values.materials_id[i]);
          }
        }
        if (values.printers_id) {
          for (let i = 0; i < values.printers_id.length; i++) {
            await fetchTogglePrinters(res.id, values.printers_id[i]);
          }
        }
        navigate(`/admin/venders`);
      }
    });
  };

  const validator = (_, { valid }) => {
    if (valid()) return Promise.resolve();
    return Promise.reject(i18n.t("IncorrectNumber"));
  };

  return (
    <Form
      layout="vertical"
      requiredMark={false}
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      autoComplete="off"
      className="modalForm"
      style={{ marginTop: 16 }}
    >
      <Form.Item
        label={i18n.t("Name")}
        name="first_name"
        rules={[
          {
            required: true,
            message: i18n.t("ThisRequiredField"),
          },
        ]}
      >
        <Input placeholder={i18n.t("EnterName")} />
      </Form.Item>
      <Form.Item
        label={i18n.t("LastName")}
        name="last_name"
        rules={[
          {
            required: true,
            message: i18n.t("ThisRequiredField"),
          },
        ]}
      >
        <Input placeholder={i18n.t("EnterLastName")} />
      </Form.Item>
      <Form.Item
        label={i18n.t("PhoneNumber")}
        name="phone"
        rules={[{ validator }]}
      >
        <PhoneInput enableSearch />
      </Form.Item>
      <Form.Item
        label={i18n.t("Email")}
        name="email"
        rules={[
          {
            required: true,
            message: i18n.t("ThisRequiredField"),
          },
        ]}
      >
        <Input placeholder={i18n.t("EnterEmail")} />
      </Form.Item>
      <Form.Item
        label={i18n.t("Password")}
        name="password"
        rules={[
          {
            required: true,
            message: i18n.t("ThisRequiredField"),
          },
        ]}
      >
        <Input.Password placeholder={i18n.t("EnterPassword")} />
      </Form.Item>
      <Form.Item
        label={i18n.t("NameOfTheOrganization")}
        name="organization_name"
        rules={[
          {
            required: false,
            message: i18n.t("ThisRequiredField"),
          },
        ]}
      >
        <Input placeholder={i18n.t("EnterNameOfTheOrganization")} />
      </Form.Item>
      <Form.Item
        label={i18n.t("LegalAddress")}
        name="legal_address"
        rules={[
          {
            required: false,
            message: i18n.t("ThisRequiredField"),
          },
        ]}
      >
        <Input placeholder={i18n.t("EnterLegalAddress")} />
      </Form.Item>
      <Form.Item
        label={i18n.t("INN")}
        name="inn"
        rules={[
          {
            required: false,
            message: i18n.t("ThisRequiredField"),
          },
        ]}
      >
        <Input placeholder={i18n.t("EnterINN")} />
      </Form.Item>
      <Form.Item
        label={i18n.t("KPP")}
        name="kpp"
        rules={[
          {
            required: false,
            message: i18n.t("ThisRequiredField"),
          },
        ]}
      >
        <Input placeholder={i18n.t("EnterKPP")} />
      </Form.Item>
      <Form.Item
        label={i18n.t("OGRN")}
        name="ogrnip"
        rules={[
          {
            required: false,
            message: i18n.t("ThisRequiredField"),
          },
        ]}
      >
        <Input placeholder={i18n.t("EnterOGRN")} />
      </Form.Item>
      <Form.Item
        label={i18n.t("NameOfTheBank")}
        name="bank_name"
        rules={[
          {
            required: false,
            message: i18n.t("ThisRequiredField"),
          },
        ]}
      >
        <Input placeholder={i18n.t("EnterNameOfTheBank")} />
      </Form.Item>
      <Form.Item
        label={i18n.t("PaymentAccount")}
        name="bank_account"
        rules={[
          {
            required: false,
            message: i18n.t("ThisRequiredField"),
          },
        ]}
      >
        <Input placeholder={i18n.t("EnterPaymentAccount")} />
      </Form.Item>
      <Form.Item
        label={i18n.t("BICBank")}
        name="bic"
        rules={[
          {
            required: false,
            message: i18n.t("ThisRequiredField"),
          },
        ]}
      >
        <Input placeholder={i18n.t("EnterBICBank")} />
      </Form.Item>
      <Form.Item
        label={i18n.t("CorrespondentAccountOfTheBank")}
        name="correspondent_account"
        rules={[
          {
            required: false,
            message: i18n.t("ThisRequiredField"),
          },
        ]}
      >
        <Input placeholder={i18n.t("EnterCorrespondentAccountOfTheBank")} />
      </Form.Item>
      <Form.Item
        label={i18n.t("Commission")}
        name="commission"
        rules={[
          {
            required: true,
            message: i18n.t("ThisRequiredField"),
          },
        ]}
      >
        <Input placeholder={i18n.t("EnterCommission")} type="number" />
      </Form.Item>
      <Form.Item
        label={i18n.t("Materials")}
        name="materials_id"
        style={{ width: "100%" }}
      >
        <Select
          placeholder={i18n.t("SelectMaterial")}
          style={{ width: "100%" }}
          mode="multiple"
          // onSelect={(e) => onSelect(e)}
          // onDeselect={(e) => onDeselect(e)}
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          options={materials.map((el) => ({
            value: el.id,
            label: el.value,
          }))}
        />
      </Form.Item>
      <Form.Item
        label={i18n.t("Printers")}
        name="printers_id"
        style={{ width: "100%" }}
      >
        <Select
          placeholder={i18n.t("SelectPrinters")}
          style={{ width: "100%" }}
          mode="multiple"
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          options={printers.map((el) => ({
            value: el.id,
            label: el.value,
          }))}
        />
      </Form.Item>
      <Form.Item
        label={i18n.t("Address")}
        name="address"
        rules={[
          {
            required: true,
            message: i18n.t("ThisRequiredField"),
          },
        ]}
      >
        <Input placeholder={i18n.t("EnterAddress")} />
      </Form.Item>
      <div className="locationBlock">
        <div className="locationBlock_map">
          <Map
            defaultState={{
              center: [55.751574, 37.573856],
              zoom: 15,
              type: "yandex#hybrid",
            }}
            width="100%"
            height="100%"
            onClick={(e) => {
              setCoords(e.get("coords"));
            }}
          >
            <FullscreenControl />
            <SearchControl options={{ float: "right" }} />
            <GeolocationControl options={{ float: "left" }} />
            <TypeSelector options={{ float: "right" }} />
            {coords && <Placemark geometry={coords} />}
          </Map>
        </div>
      </div>
      <Form.Item>
        <FormButton
          title={i18n.t("Create")}
          htmlType="submit"
          style={{ marginTop: 16 }}
        />
      </Form.Item>
    </Form>
  );
}

function mapStateToProps(state) {
  return {
    materials: state.materials.materials,
    printers: state.printers.printers,
    roles: state.roles.roles,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchCreateUser: (user_data) => dispatch(fetchCreateUser(user_data)),
    fetchTogglePrinters: (user_id, printer_id) =>
      dispatch(fetchTogglePrinters(user_id, printer_id)),
    fetchToggleMaterials: (user_id, material_id) =>
      dispatch(fetchToggleMaterials(user_id, material_id)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateVenderForm);
