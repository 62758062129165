export const adminListOrderStatuses = [
  {
    status: "CREATED",
    title: "SendForDistribution",
    bg: "#FFC107",
  },
  {
    status: "DISTIBUTING",
    title: "AtTheDistribution",
    bg: "#3949AB",
  },
  {
    status: "IN_PROGRESS",
    title: "InProgress",
    bg: "#43A047",
  },
  {
    status: "VENDOR_DONE",
    title: "SubmittedForInspection",
    bg: "#43A047",
  },
  {
    status: "IN_SHIPPING",
    title: "AwaitingShipment",
    bg: "#FFC107",
  },
  {
    status: "COMPLITED",
    title: "Done",
    bg: "#43A047",
  },
  {
    status: "STOPPED",
    title: "Stopped",
    bg: "#D32F2F",
  },
  {
    status: "ON_REVISION",
    title: "UnderDevelopment",
    bg: "#D32F2F",
  },
  {
    status: "REJECTED",
    title: "Deactivated",
    bg: "#D32F2F",
  },
];

export const adminListDistributionStatuses = [
  {
    status: "ACCEPTED",
    title: "Accepted",
    bg: "#43A047",
  },
  {
    status: "REJECTED",
    title: "Rejected",
    bg: "#D32F2F",
  },
  {
    status: "CANCELED",
    title: "NoAnswer",
    bg: "#EF6C00",
  },
];

export const deleteDistributeList = ["ACCEPTED", "ON_REVISION"];
