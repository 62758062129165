import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import './style.css';

const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 14,
        color: 'white',
      }}
      spin
    />
  );

function FormButton({ title, loading, ...props }) {
    return (
        <button
            className="formButton whiteButtonText"
            {...props}
        >
            {loading ? <Spin indicator={antIcon} /> : title }
        </button>
    );
}

export default FormButton;