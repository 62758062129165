import { Dropdown, Select, Form } from "antd";
import { connect } from "react-redux";
import {
  EXIT,
  UPDATE_ACTIVE_CURRENCY,
  UPDATE_USER_DATA,
} from "store/actionTypes";
import { SET_VISIBLE_MODAL } from "store/actionTypes";
// import HeaderLogo from 'assets/images/headerLogo.svg';
import angleDown from "assets/images/icons/angle-down.svg";

import "components/layout/media.css";
import "components/layout/style.css";
import { useEffect, useState } from "react";
import { getAllCurrencies } from "store/actions/admin/currency";
import { getAllLanguages } from "store/actions/admin/language";
import { updateCurrency, updateLanguage } from "store/actions/user/user";
import { fetchSuccess } from "store/actions/fetchStatuses";
import { useTranslation } from "react-i18next";

function Header({
  userData,
  EXIT,
  setVisible,
  currencies,
  languages,
  getAllCurrencies,
  getAllLanguages,
  updateCurrency,
  updateLanguage,
  changeActiveCurrency,
  updateUserData,
}) {
  const [currencyList, setCurrencyList] = useState([]);
  const [languageList, setLanguageList] = useState([]);
  const [items, setItems] = useState([]);
  const [menuVisible, setMenuVisible] = useState(false);
  const { i18n } = useTranslation();

  useEffect(() => {
    getAllCurrencies();
  }, [getAllCurrencies]);

  useEffect(() => {
    getAllLanguages();
  }, [getAllLanguages]);

  useEffect(() => {
    if (currencies.length) {
      setCurrencyList(currencies);
    }
    if (languages.length) {
      setLanguageList(languages);
    }
  }, [currencies, languages]);

  useEffect(() => {
    if (userData) {
      changeActiveCurrency(
        currencies.find((e) => e.code === userData.currency)
      );
    }
  }, [userData, currencies]);

  useEffect(() => {
    let user = localStorage.getItem("studia3d-agregator-data");
    user = JSON.parse(user);

    if (!userData) {
      updateUserData(user);
    }

    if (
      languageList?.length &&
      (!user.language ||
        !languageList.find((language) => language.code === user.language))
    ) {
      selectLanguage(languageList[0].code);
    }

    if (
      currencyList?.length &&
      (!user.currency ||
        !currencyList.find((language) => language.code === user.currency))
    ) {
      selectCurrency(currencyList[0].code);
    }

    if (
      user.is_superuser ||
      user.email === "admin@admin.com" ||
      user?.role?.name === "manager"
    ) {
      setItems([
        {
          label: (
            <Form.Item
              name="language"
              style={{ marginBottom: 0, minWidth: 200, width: "100%" }}
            >
              <p style={{ fontSize: 0 }}>{user.language}</p>
              <Select
                placeholder={i18n.t("Language")}
                value={user.language}
                options={languageList.map((e) => ({
                  label: e.name,
                  value: e.code,
                }))}
                onSelect={(e) => {
                  selectLanguage(e);
                  setMenuVisible(false);
                }}
              />
            </Form.Item>
          ),
          key: 0,
          disabled: true,
        },
        {
          label: (
            <Form.Item
              placeholder={i18n.t("SelectCurrency")}
              name="currency"
              style={{ marginBottom: 0, minWidth: 200, width: "100%" }}
            >
              <p style={{ fontSize: 0 }}>{user.currency}</p>
              <Select
                placeholder={i18n.t("Currency")}
                value={user.currency}
                options={currencyList.map((e) => ({
                  label: `${e.symbol} ${e.name}`,
                  value: e.code,
                }))}
                onSelect={(e) => {
                  selectCurrency(e);
                  setMenuVisible(false);
                }}
              />
            </Form.Item>
          ),
          key: 1,
          disabled: true,
        },
        {
          label: (
            <p
              onClick={() => {
                setVisible({}, "changeCurrency");
                setMenuVisible(false);
              }}
            >
              {i18n.t("ChangeCurrency")}
            </p>
          ),
          key: 2,
        },
        {
          label: (
            <p style={{ color: "red" }} onClick={EXIT}>
              {i18n.t("Exit")}
            </p>
          ),
          key: 3,
        },
      ]);
    } else {
      setItems([
        {
          label: (
            <Form.Item
              name="language"
              style={{ marginBottom: 0, minWidth: 200, width: "100%" }}
            >
              <p style={{ fontSize: 0 }}>{user.language}</p>
              <Select
                placeholder={i18n.t("Language")}
                value={user.language}
                options={languageList.map((e) => ({
                  label: e.name,
                  value: e.code,
                }))}
                onSelect={(e) => {
                  selectLanguage(e);
                  setMenuVisible(false);
                }}
              />
            </Form.Item>
          ),
          key: 0,
          disabled: true,
        },
        {
          label: (
            <Form.Item
              placeholder={i18n.t("SelectCurrency")}
              name="currency"
              style={{ marginBottom: 0, minWidth: 200, width: "100%" }}
            >
              <p style={{ fontSize: 0 }}>{user.currency}</p>
              <Select
                placeholder={i18n.t("Currency")}
                value={user.currency}
                options={currencyList.map((e) => ({
                  label: `${e.symbol} ${e.name}`,
                  value: e.code,
                }))}
                onSelect={(e) => {
                  selectCurrency(e);
                  setMenuVisible(false);
                }}
              />
            </Form.Item>
          ),
          key: 1,
          disabled: true,
        },
        {
          label: (
            <p style={{ color: "red" }} onClick={EXIT}>
              {i18n.t("Exit")}
            </p>
          ),
          key: 2,
        },
      ]);
    }
  }, [currencyList, userData]);

  const selectCurrency = (event) => {
    updateCurrency(event);
  };
  const selectLanguage = (event) => {
    updateLanguage(event);
  };

  return (
    <div className="userHeader">
      <div className="userHeader_leftPart">
        {/* <img src={HeaderLogo} alt="HeaderLogo" /> */}
      </div>
      <div className="userHeader_rightPart">
        <Dropdown
          open={menuVisible}
          onOpenChange={setMenuVisible}
          menu={{ items }}
          trigger={["click"]}
        >
          <a onClick={(e) => e.preventDefault()} className="userMenu">
            <div className="userHeader_rightPart__avatar__text p14_500">A</div>
            <img
              src={angleDown}
              alt="angleDown"
              className="userHeader_rightPart__menu"
            />
          </a>
        </Dropdown>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    currencies: state.currency.currencies,
    languages: state.language.languages,
    userData: state.user.user_data,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    EXIT: () => dispatch({ type: EXIT }),
    setVisible: (data, modal_type) =>
      dispatch({ type: SET_VISIBLE_MODAL, modal_type, data }),
    getAllCurrencies: () => dispatch(getAllCurrencies()),
    getAllLanguages: () => dispatch(getAllLanguages()),
    updateLanguage: (language) => dispatch(updateLanguage(language)),
    updateCurrency: (currency) => dispatch(updateCurrency(currency)),
    updateUserData: (user) => dispatch(fetchSuccess(UPDATE_USER_DATA, user)),
    changeActiveCurrency: (currency) =>
      dispatch(fetchSuccess(UPDATE_ACTIVE_CURRENCY, currency)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
