import { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { connect } from "react-redux";
import { routingList } from "configs/routings/routings";
import CustomModal from "components/shared/customModal/CustomModal";
import { useTranslation } from "react-i18next";

import "./App.css";
import "antd/dist/reset.css";
import "assets/styles/globalStyles.css";

function App({ access_token, role, userData }) {
  const [routing, setRouting] = useState([]);
  const { i18n } = useTranslation();

  useEffect(() => {
    if (userData?.language) {
      const languageCode = userData.language.split("-")[0];
      if (i18n.language !== languageCode) {
        i18n.changeLanguage(languageCode);
      }
    }
  }, [userData, i18n]);

  useEffect(() => {
    if (!access_token) {
      setRouting(routingList.no_auth);
    } else {
      setRouting(routingList[role]);
    }
  }, [access_token]);

  return (
    <>
      <Routes>
        {routing.map((item) => {
          if (item.children) {
            return (
              <>
                <Route path={item.path} element={item.element}>
                  {item.children.map((item, key) => {
                    if (item.index) {
                      return (
                        <Route
                          index
                          element={item.element}
                          key={`route_${key}`}
                        />
                      );
                    } else {
                      return (
                        <Route
                          path={item.path}
                          element={item.element}
                          key={`route_${key}`}
                        />
                      );
                    }
                  })}
                </Route>
                <Route
                  path="*"
                  element={<Navigate to={item.navigate} replace />}
                />
              </>
            );
          }
        })}
      </Routes>
      <CustomModal />
    </>
  );
}

function mapStateToProps(state) {
  return {
    access_token: state.userEnv.access_token,
    role: state.userEnv.role,
    userData: state.user.user_data,
  };
}

export default connect(mapStateToProps, null)(App);
