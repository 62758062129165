import { useState } from "react";
import { NavLink } from "react-router-dom";

import menu from "assets/images/drawerMenuIcons/menu.svg";

import "./media.css";
import "./style.css";

const DrawerMenu = ({ menuList, menuSub }) => {
  const [collapsed, setCollapsed] = useState(false);
  return (
    <>
      <div className={`drawerMenu ${collapsed ? "active" : ""}`}>
        <div className="drawerMenu_mainLinks">
          <img
            src={menu}
            alt="menu"
            className="drawerMenu_menuButton"
            onClick={() => setCollapsed(!collapsed)}
          />
          {menuList.map((item, key) => (
            <NavLink
              to={item.path}
              key={`drawerMenuLink_${key}`}
              className={({ isActive }) =>
                isActive ? "drawerMenu_link active-link" : "drawerMenu_link"
              }
              onClick={() => setCollapsed(false)}
            >
              <div className="drawerMenu_link__item">
                <div>{item.icon}</div>
                <p className="mainText">{item.title}</p>
              </div>
            </NavLink>
          ))}
        </div>
        {/* {collapsed &&
                    <div className='drawerMenu_subLinks'>
                        {menuSub.map((item, key) => (
                            <Link to={item.to} className='mainText drawerMenu_subLinks__item' key={`drawerMenu_subLinks_${key}`}>{item.title}</Link>
                        ))}
                    </div>
                } */}
      </div>
      {collapsed && (
        <div
          className={`drawerMenuBg`}
          onClick={() => setCollapsed(!collapsed)}
        ></div>
      )}
    </>
  );
};
export default DrawerMenu;
