import CreateOrderForm from "components/components/forms/createOrder/CreateOrder";
import Breadcrumb from "components/shared/breadcrumb/Breadcrumb";
import TextHeading from "components/ui/teaxtHeading/TextHeading";
import { adminOrdersBreadcrumb } from "data/breadcrumb";
import { useTranslation } from "react-i18next";

const CreateOrder = () => {
  const { i18n } = useTranslation();
  return (
    <div className="contentBlock">
      <Breadcrumb
        list={adminOrdersBreadcrumb.map((e) => i18n.t(e.title))}
        activeLink={i18n.t("CreatingOrder")}
      />
      <TextHeading text={i18n.t("CreatingOrder")} />
      <CreateOrderForm />
    </div>
  );
};

export default CreateOrder;
