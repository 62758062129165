import {
  FETCH_ALL_CURRENCIES_START,
  FETCH_ALL_CURRENCIES_SUCCESS,
  FETCH_SEND_CURRENCY_START,
  FETCH_SEND_CURRENCY_SUCCESS,
} from "../../actionTypes";
import { fetchStart, fetchSuccess } from "../fetchStatuses";
import { api_currency } from "api/admin/currency";
import { errorNotification, successNotification } from "hooks/notifications";

export function createCurrency(currencies, setVisible) {
  return async (dispatch) => {
    dispatch(fetchStart(FETCH_SEND_CURRENCY_START));
    try {
      const operations = await api_currency.CreateCurrency(currencies);
      dispatch(
        fetchSuccess(FETCH_SEND_CURRENCY_SUCCESS, {
          data: operations.data.data.items,
        })
      );
      setVisible();
      successNotification("TheCurrencyIsCreated");
    } catch (e) {
      errorNotification("AnErrorOccurredWhileReceivingCurrency");
    }
  };
}

export function getAllCurrencies() {
  return async (dispatch) => {
    dispatch(fetchStart(FETCH_ALL_CURRENCIES_START));
    try {
      const operations = await api_currency.GetCurrencies();
      dispatch(
        fetchSuccess(FETCH_ALL_CURRENCIES_SUCCESS, {
          data: operations.data.data.items,
        })
      );
    } catch (e) {
      errorNotification("AnErrorOccurredWhileReceivingCurrency");
    }
  };
}

export function updateCurrency(currency) {
  return async (dispatch) => {
    dispatch(fetchStart(FETCH_SEND_CURRENCY_START));
    try {
      const operations = await api_currency.UpdateCurrency(currency);
      dispatch(
        fetchSuccess(FETCH_SEND_CURRENCY_SUCCESS, {
          data: operations.data.data.items,
        })
      );
      successNotification("CurrencyUpdated");
    } catch (e) {
      errorNotification("AnErrorOccurredWhileReceivingCurrency");
    }
  };
}

export function deleteCurrency(currency_id) {
  return async (dispatch) => {
    dispatch(fetchStart(FETCH_SEND_CURRENCY_START));
    try {
      const operations = await api_currency.DeleteCurrency(currency_id);
      dispatch(
        fetchSuccess(FETCH_SEND_CURRENCY_SUCCESS, {
          data: operations.data.data.items,
        })
      );
      successNotification("CurrencyRemoved");
    } catch (e) {
      errorNotification("AnErrorOccurredWhileReceivingCurrency");
    }
  };
}
