import {
  FETCH_USER_DATA_START,
  FETCH_USER_DATA_SUCCESS,
  CLEARE_USER_DATA,
  UPDATE_ACTIVE_CURRENCY,
  UPDATE_USER_DATA,
} from "../../actionTypes";

const initialState = {
  user_data: null,
  activeCurrency: null,
};

// TODO: remove it
const activeCurrency = {
  code: "USD",
  name: "Russian Ruble",
  rate: 115,
  symbol: "$",
  short: "$",
  is_active: true,
  id: "fc5047fe-369f-4b78-9efb-900024055e83",
};

export default function initialReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_USER_DATA_START:
      return {
        ...state,
        loading: true,
      };
    case FETCH_USER_DATA_SUCCESS:
      localStorage.setItem(
        "studia3d-agregator-data",
        JSON.stringify(action.data.user_data)
      );
      return {
        ...state,
        activeCurrency: activeCurrency,
        user_data: action.data.user_data,
      };
    case UPDATE_USER_DATA:
      return {
        ...state,
        user_data: action.data,
      };
    case CLEARE_USER_DATA:
      return {
        activeCurrency: null,
        user_data: null,
      };
    case UPDATE_ACTIVE_CURRENCY:
      return {
        ...state,
        activeCurrency: action.data || activeCurrency,
      };
    default:
      return state;
  }
}
