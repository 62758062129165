import {
  FETCH_ALL_LANGUAGES_START,
  FETCH_ALL_LANGUAGES_SUCCESS,
  FETCH_SEND_LANGUAGE_START,
  FETCH_SEND_LANGUAGE_SUCCESS,
} from "../../actionTypes";
import { fetchStart, fetchSuccess } from "../fetchStatuses";
import { api_language } from "api/admin/language";
import { errorNotification, successNotification } from "hooks/notifications";

export function createLanguage(currencies, setVisible) {
  return async (dispatch) => {
    dispatch(fetchStart(FETCH_SEND_LANGUAGE_START));
    try {
      const operations = await api_language.CreateLanguage(currencies);
      dispatch(
        fetchSuccess(FETCH_SEND_LANGUAGE_SUCCESS, {
          data: operations.data.data.items,
        })
      );
      setVisible();
      successNotification("LanguageIsCreated");
    } catch (e) {
      errorNotification("AnErrorOccurredWhileCreatingTheLanguage");
    }
  };
}

export function getAllLanguages() {
  return async (dispatch) => {
    dispatch(fetchStart(FETCH_ALL_LANGUAGES_START));
    try {
      const operations = await api_language.GetLanguages();
      dispatch(
        fetchSuccess(FETCH_ALL_LANGUAGES_SUCCESS, {
          data: operations.data.data.items,
        })
      );
    } catch (e) {
      errorNotification("AnErrorOccurredWhileRetrievingLanguages");
    }
  };
}

export function deleteLanguage(currency_id) {
  return async (dispatch) => {
    dispatch(fetchStart(FETCH_SEND_LANGUAGE_START));
    try {
      const operations = await api_language.DeleteLanguage(currency_id);
      dispatch(
        fetchSuccess(FETCH_SEND_LANGUAGE_SUCCESS, {
          data: operations.data.data.items,
        })
      );
      successNotification("LanguageRemoved");
    } catch (e) {
      errorNotification("AnErrorOccurredWhileDeletingTheLanguage");
    }
  };
}
