import AdminLayout from "components/layout/admin/AdminLayout";
import AllFinanceAdmin from "views/admin/finance/allFinance/AllFinanceAdmin";

import AllOrdersAdmin from "views/admin/orders/allOrders/AllOrdersAdmin";
import CreateOrder from "views/admin/orders/createOrder/CreateOrder";
import OrderByIdAdmin from "views/admin/orders/orderById/OrderByIdAdmin";
import AllVendersAdmin from "views/admin/vendors/allVenders/AllVendersAdmin";
import CreateVender from "views/admin/vendors/createVender/CreateVender";
import VenderByIdAdmin from "views/admin/vendors/venderById/VenderById";

export const adminRoutings = [
    {
        path: '/admin/',
        element: <AdminLayout />,
        navigate: '/admin/orders',
        children: [
            {
                path: 'orders',
                index: false,
                element: <AllOrdersAdmin />
            },
            {
                path: 'orders/:id',
                index: false,
                element: <OrderByIdAdmin />
            },
            {
                path: 'orders/create',
                index: false,
                element: <CreateOrder />
            },
            // {
            //     path: 'contractors',
            //     index: false,
            //     element: <AllContractorsAdmin />
            // },
            {
                path: 'venders',
                index: false,
                element: <AllVendersAdmin />
            },
            {
                path: 'venders/create',
                index: false,
                element: <CreateVender />
            },
            {
                path: 'venders/:id',
                index: false,
                element: <VenderByIdAdmin />
            },
            {
                path: 'finance',
                index: false,
                element: <AllFinanceAdmin />
            },
        ]
    }
]