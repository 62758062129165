export const leadToCurrencyWithSymbol = (activeCurrency, price, toFixed) => {
  let priceResult = leadToCurrency(activeCurrency, price);
  return `${toFixed ? priceResult.toFixed(toFixed) : priceResult} ${
    activeCurrency.symbol
  }`;
};

export const leadToCurrency = (activeCurrency, price) => {
  return +price / activeCurrency.rate;
};

export const leadToStandartCurrency = (activeCurrency, price) => {
  return +price * activeCurrency.rate;
};
