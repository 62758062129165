import { ApiUrl, instance } from '../apiEnv';

export const api_user = {

    async GetUserMe() {
        return await instance.get(`${ApiUrl}user`)
    },

    async GetMyOperations(page, size, params) {
        return await instance.get(`${ApiUrl}user/me/operations?page=${page}&size=${size}${params ? `&${params}` : ''}`)
    },
    
    async PutUpdateUserMe(data) {
        return await instance.put(`${ApiUrl}user`, data)
    },

    async PatchTogglePrinters(printer_id) {
        return await instance.patch(`${ApiUrl}user/toggle_printers/${printer_id}`)
    },

    async PatchToggleMaterials(material_id) {
        return await instance.patch(`${ApiUrl}user/toggle_materials/${material_id}`)
    },

    async UpdateLanguage(language) {
        return await instance.patch(`${ApiUrl}user/me/language`, {
            language
        })
    },
    async UpdateCurrency(currency) {
        return await instance.patch(`${ApiUrl}user/me/currency`, {
            currency
        })
    }
}