import { api_printers } from 'api/printers'
import {
    FETCH_ALL_PRINTERS_START,
    FETCH_ALL_PRINTERS_SUCCESS,
} from '../actionTypes'
import { fetchStart, fetchSuccess } from './fetchStatuses'

export function fetchPrinters(page, size, params) {
    return async dispatch => {
        dispatch(fetchStart(FETCH_ALL_PRINTERS_START))
        try {
            const printers = await api_printers.GetPrinters(page, size, params)
            dispatch(fetchSuccess(FETCH_ALL_PRINTERS_SUCCESS, {
                data: printers.data.data
            }))
        } catch (e) {
        }
    }
}