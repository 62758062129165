// .map((e) => ({ ...e, title: i18n.t(e.title) }))
export const allOrdersTable = [
  {
    title: "OrderID",
  },
  {
    title: "ExternalOrderID",
  },
  {
    title: "DateOfCreation",
  },
  {
    title: "APrice",
  },
  {
    title: "FullNameOfCustomer",
  },
  {
    title: "CustomersEmail",
  },
  {
    title: "CustomersPhoneNumber",
  },
  {
    title: "Status",
  },
  {
    title: "",
  },
];

export const allUserOrdersTable = [
  {
    title: "OrderID",
  },
  {
    title: "DateOfCreation",
  },
  {
    title: "APrice",
  },
  // {
  //     title: 'Время печати',
  // },
  {
    title: "Status",
  },
  {
    title: "",
  },
];

export const allUserOffersTable = [
  {
    title: "OrderID",
  },
  {
    title: "DateOfCreation",
  },
  // {
  //     title: 'Цена',
  // },
  {
    title: "PlaceInLine",
  },
  {
    title: "PrintTime",
  },
  {
    title: "Status",
  },
  {
    title: "",
  },
];

export const allContractorsTable = [
  {
    title: "DateAndTime",
  },
  {
    title: "TransactionID",
  },
  {
    title: "FullNameOfCustomer",
  },
  {
    title: "FullNameOfPerformer",
  },
  {
    title: "Reason",
  },
  {
    title: "",
  },
];

export const productsInOrderTable = [
  {
    title: "Material",
  },
  {
    title: "Color",
  },
  {
    title: "Filling",
  },
  {
    title: "Printer",
  },
  {
    title: "Quantity",
  },
  // {
  //     title: 'Цена',
  // },
  {
    title: "3Dmodel",
  },
];

export const distributionsInOrderTable = [
  {
    title: "Email",
  },
  {
    title: "EmailSendingTime",
  },
  {
    title: "ReasonRefusal",
  },
  {
    title: "Status",
  },
  {
    title: "",
  },
];

export const createDistributionTable = [
  {
    title: "FullName",
  },
  {
    title: "Email",
  },
  {
    title: "PhoneNumber",
  },
  {
    title: "Address",
  },
  {
    title: "AvailablePrinters",
  },
  {
    title: "AvailableMaterials",
  },
  {
    title: "",
  },
];

export const reviewInOrderTable = [
  {
    title: "PerformerID",
  },
  {
    title: "ManagersResponse",
  },
  {
    title: "PerformersComment",
  },
  {
    title: "Status",
  },
  {
    title: "",
  },
];

export const allVendorsTable = [
  {
    title: "ID",
  },
  {
    title: "FullName",
  },
  {
    title: "Email",
  },
  {
    title: "PhoneNumber",
  },
  {
    title: "Materials",
  },
  {
    title: "Printers",
  },
  {
    title: "ActiveA",
  },
  {
    title: "Verified",
  },
  {
    title: "",
  },
];

export const allUserFinanceTable = [
  {
    title: "OrderID",
  },
  {
    title: "DateOfReceipt",
  },
  {
    title: "Sum",
  },
  {
    title: "OperationType",
  },
  {
    title: "Status",
  },
];

export const allAdminFinanceTable = [
  {
    title: "OrderID",
  },
  {
    title: "UserEmail",
  },
  {
    title: "DateOfReceipt",
  },
  {
    title: "Sum",
  },
  {
    title: "OperationType",
  },
  // {
  //     title: 'ИНН',
  // },
  // {
  //     title: 'КПП',
  // },
  // {
  //     title: 'ОГРН',
  // },
  // {
  //     title: 'Банк',
  // },
  // {
  //     title: 'БИК банк',
  // },
  // {
  //     title: 'Кор. счет',
  // },
  {
    title: "Status",
  },
  {
    title: "",
  },
];

export const documentsInOrderTable = [
  {
    title: "NameA",
  },
  {
    title: "Type",
  },
  {
    title: "",
  },
];
