import {
    FETCH_ALL_VENDERS_START,
    FETCH_ALL_VENDERS_SUCCESS,
    FETCH_ALL_VENDERS_SUCCESS_CONCAT
} from '../../actionTypes'

const initialState = {
    venders: [],
    pages: 0,
    page: 0,
    size: 0,
    loading: false
}

export default function initialReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_ALL_VENDERS_START:
            return {
                ...state, loading: true
            }
        case FETCH_ALL_VENDERS_SUCCESS:
            return {
                venders: action.data.data.items,
                pages: action.data.data.pages,
                page: action.data.data.page,
                size: action.data.data.size,
                loading: false
            }
        case FETCH_ALL_VENDERS_SUCCESS_CONCAT:
            return {
                ...state,
                venders: state.venders.concat(action.data.data.items),
            }
        default: return state
    }
}