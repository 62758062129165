import { notification } from "antd";
import deleteIcon from "assets/images/icons/delete.svg";
import edit_pen from "assets/images/icons/edit_pen.svg";
import SimpleButton from "components/shared/buttons/simpleButton/SimpleButton";
import Pagination from "components/shared/pagination/Pagination";
import Search from "components/shared/search/Search";
import Table from "components/shared/table/Table";
import TextHeading from "components/ui/teaxtHeading/TextHeading";
import { allVendorsTable } from "data/tableHeader";
import { allVendorsTableWidth } from "data/tableWidth";
import { createFio } from "hooks/createFio";
import { createQuery } from "hooks/createQuery";
import { warningNotification } from "hooks/notifications";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  fetchAllVendersWithoutConcat,
  fetchDeleteUser,
} from "store/actions/admin/users";
import { useTranslation } from "react-i18next";

const AllVendersAdmin = ({
  fetchAllVendersWithoutConcat,
  fetchDeleteUser,
  venders,
  pages,
}) => {
  const { i18n } = useTranslation();
  const [api, contextHolder] = notification.useNotification();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");

  useEffect(() => {
    const query = Object.fromEntries([...searchParams]);
    if (query.page !== undefined) {
      fetchAllVendersWithoutConcat(
        query.page,
        50,
        createQuery(
          Object.fromEntries([...searchParams]).search,
          Object.fromEntries([...searchParams]).order_by,
          Object.fromEntries([...searchParams]).order
        ),
        search
      );
    } else {
      query.page = 1;
      setSearchParams(query);
    }
  }, [
    Object.fromEntries([...searchParams]).page,
    Object.fromEntries([...searchParams]).search,
    Object.fromEntries([...searchParams]).order_by,
    Object.fromEntries([...searchParams]).order,
    search,
  ]);

  const createTableData = () => {
    return venders.map((el) => [
      {
        type: "active_text",
        content: (
          <span onClick={() => navigate(`/admin/venders/${el.id}`)}>
            {el.id}
          </span>
        ),
      },
      {
        type: "text",
        content: createFio(el) || "-",
      },
      {
        type: "text",
        content: el.email || "-",
      },
      {
        type: "text",
        content: el.phone || "-",
      },
      {
        type: "text",
        content: (
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            {el.materials.length
              ? el.materials.map((material, key) => {
                  if (key === el.materials.length - 1) {
                    return <span>{material.value}</span>;
                  } else {
                    return <span>{material.value + ", "}</span>;
                  }
                })
              : "-"}
          </div>
        ),
      },
      {
        type: "text",
        content: (
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            {el.printers.length
              ? el.printers.map((printer, key) => {
                  if (key === el.printers.length - 1) {
                    return <span>{printer.value}</span>;
                  } else {
                    return <span>{printer.value + ", "}</span>;
                  }
                })
              : "-"}
          </div>
        ),
      },
      {
        type: "text",
        content: el.is_active ? i18n.t("Yes") : i18n.t("No"),
      },
      {
        type: "text",
        content: el.is_verified ? i18n.t("Yes") : i18n.t("No"),
      },
      {
        type: "actions",
        content: (
          <div className="tableIconsBlock">
            <img
              src={edit_pen}
              alt="edit_pen"
              onClick={() =>
                navigate(`/admin/venders/${el.id}`, {
                  state: { edit: true },
                })
              }
            />
            <img
              src={deleteIcon}
              alt="deleteIcon"
              onClick={() =>
                warningNotification(
                  api,
                  async () => {
                    await fetchDeleteUser(el.id);
                    await fetchAllVendersWithoutConcat(
                      Object.fromEntries([...searchParams]).page,
                      50,
                      createQuery(
                        Object.fromEntries([...searchParams]).search,
                        Object.fromEntries([...searchParams]).order_by,
                        Object.fromEntries([...searchParams]).order
                      ),
                      search
                    );
                    api.destroy();
                  },
                  i18n.t("removingPerformer")
                )
              }
            />
          </div>
        ),
      },
    ]);
  };

  return (
    <div className="contentBlock">
      {contextHolder}
      <div className="contentBlock_header">
        <div
          className="contentBlock_header__leftHalf"
          style={{
            gap: 24,
          }}
        >
          <TextHeading text={i18n.t("Performers")} />
          <Search value={search} setValue={setSearch} />
        </div>
        <div className="contentBlock_header__rightHalf">
          {/* <Filter
                        filterList={adminContractorsFilter.map((e) => ({...e,title:i18n.t(e.title)}))}
                    /> */}
          <SimpleButton
            title={i18n.t("createPerformer")}
            className="blueButton"
            onClick={() => navigate(`/admin/venders/create`)}
          />
        </div>
      </div>
      <Table
        header={allVendorsTable.map((e) => ({ ...e, title: i18n.t(e.title) }))}
        width={allVendorsTableWidth}
        data={createTableData()}
      />
      {venders.length > 0 && <Pagination pages={pages} />}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    venders: state.venders.venders,
    pages: state.venders.pages,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchAllVendersWithoutConcat: (page, size, params, search_str) =>
      dispatch(fetchAllVendersWithoutConcat(page, size, params, search_str)),
    fetchDeleteUser: (user_id) => dispatch(fetchDeleteUser(user_id)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AllVendersAdmin);
