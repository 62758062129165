import OrderRejectForm from "components/components/forms/modal/orderReject/OrderRejectForm";
import { useTranslation } from "react-i18next";

const OrderReject = () => {
  const { i18n } = useTranslation();
  return (
    <div>
      <p
        className="mainText p24_600 p_mb8"
        style={{ textAlign: "center", width: "30vw" }}
      >
        {i18n.t("RefuseTheOffer")}
      </p>
      <p className="subtext p_mb16" style={{ textAlign: "center" }}>
        {i18n.t("BeforeYouRefuseAnOfferWriteDownTheReason")}
      </p>
      <OrderRejectForm />
    </div>
  );
};

export default OrderReject;
