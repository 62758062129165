import CreateVenderForm from "components/components/forms/createVender/CreateVenderForm";
import Breadcrumb from "components/shared/breadcrumb/Breadcrumb";
import TextHeading from "components/ui/teaxtHeading/TextHeading";
import { adminVendersBreadcrumb } from "data/breadcrumb";
import { useTranslation } from "react-i18next";

const CreateVender = () => {
  const { i18n } = useTranslation();
  return (
    <div className="contentBlock">
      <Breadcrumb
        list={adminVendersBreadcrumb.map((e) => i18n.t(e.title))}
        activeLink={i18n.t("createPerformer")}
      />
      <TextHeading text={i18n.t("createPerformer")} />
      <CreateVenderForm />
    </div>
  );
};

export default CreateVender;
