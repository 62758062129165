import { ApiUrl, instance } from '../apiEnv';

export const api_orders = {

    async GetVendorList(page, size, params) {
        return await instance.get(`${ApiUrl}order/vendor/list?page=${page}&size=${size}&${params}`)
    },

    async GetVendorAccepted(page, size, params) {
        return await instance.get(`${ApiUrl}order/vendor/accepted?page=${page}&size=${size}&${params}`)
    },

    async GetOrderById(id) {
        return await instance.get(`${ApiUrl}order/${id}`)
    },

    async GetOrderPublicById(id) {
        return await instance.get(`${ApiUrl}order/public/${id}`)
    },

    async PostAcceptOrderById(order_id) {
        return await instance.post(`${ApiUrl}order/${order_id}/accept`)
    },

    async PostRejectOrderById(order_id, rejection_reason) {
        return await instance.post(`${ApiUrl}order/${order_id}/reject?rejection_reason=${rejection_reason}`)
    },

    async PostCompliteOrderById(order_id, data) {
        return await instance.post(`${ApiUrl}order/${order_id}/complite`, data)
    },
}