import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import activeSorting from "assets/images/icons/activeSorting.svg";
import activeSortingDown from "assets/images/icons/activeSortingDown.svg";
import sorting from "assets/images/icons/sorting.svg";
import noFound from "assets/images/noFound.svg";

import "./style.css";

function Table({ header, data, width }) {
  const [order_by, setOrderBy] = useState(null);
  const [order, setOrder] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const query = Object.fromEntries([...searchParams]);
    if (query.order_by) {
      setOrderBy(Object.fromEntries([...searchParams]).order_by);
    } else {
      setOrderBy(null);
    }
    if (query.order && query.order !== "null") {
      setOrder(Object.fromEntries([...searchParams]).order);
    } else {
      setOrder(null);
    }
  }, [
    Object.fromEntries([...searchParams]).order_by,
    Object.fromEntries([...searchParams]).order,
  ]);

  const countMinWidth = () => {
    let count = 0;
    width.forEach((el) => {
      if (el === null) {
        count += 100;
      } else {
        count += el;
      }
    });
    return count;
  };

  const onClickSort = (order_by) => {
    const query = Object.fromEntries([...searchParams]);
    let sortOrder = order;
    if (order === "ascendent") {
      sortOrder = "descendent";
    } else if (order === "descendent") {
      sortOrder = null;
    } else {
      sortOrder = "ascendent";
    }
    query.order_by = order_by;
    query.order = sortOrder;
    setSearchParams(query);
    setOrder(sortOrder);
    setOrderBy(order_by);
  };

  return (
    <div className="tableBlock">
      <div className="customTable" style={{ minWidth: countMinWidth() }}>
        <div className="customTable_header">
          {header.map((item, key) => (
            <div
              key={`table_header_${key}`}
              className="customTable_header__item"
              style={{
                minWidth: width[key] ? width[key] : "auto",
                maxWidth: width[key] ? width[key] : "auto",
              }}
            >
              <p className="p14_500">{item.title}</p>
              {item.sort && (
                <img
                  src={
                    item.sort_param === order_by
                      ? order !== null
                        ? order === "ascendent"
                          ? activeSorting
                          : activeSortingDown
                        : sorting
                      : sorting
                  }
                  alt="sorting"
                  onClick={() => onClickSort(item.sort_param)}
                />
              )}
            </div>
          ))}
        </div>
        <div
          className={`customTable_body ${
            data.length === 0 && "customTable_body__notFound"
          }`}
        >
          {data.map((row, key) => (
            <div className="customTable_body__row" key={`table_row_${key}`}>
              {row.map((item, index) => {
                if (item.type === "text") {
                  return (
                    <p
                      className="p14_400 mainText"
                      key={`table_row_${key}_${index}`}
                      style={{
                        minWidth: width[index] ? width[index] : "auto",
                        maxWidth: width[index] ? width[index] : "auto",
                      }}
                    >
                      {item.content}
                    </p>
                  );
                } else if (item.type === "active_text") {
                  return (
                    <p
                      className="p14_400 activeText"
                      key={`table_row_${key}_${index}`}
                      style={{
                        minWidth: width[index] ? width[index] : "auto",
                        maxWidth: width[index] ? width[index] : "auto",
                        cursor: "pointer",
                      }}
                    >
                      {item.content}
                    </p>
                  );
                } else if (item.type === "actions") {
                  return (
                    <div
                      className="customTable_body__row__actionBlock"
                      key={`table_row_${key}_${index}`}
                      style={{
                        minWidth: width[index] ? width[index] : "auto",
                        maxWidth: width[index] ? width[index] : "auto",
                        flexGrow: 1,
                      }}
                    >
                      {item.content}
                    </div>
                  );
                } else if (item.type === "wrap_text") {
                  return (
                    <div
                      className="p14_400 mainText"
                      key={`table_row_${key}_${index}`}
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        minWidth: width[index] ? width[index] : "auto",
                        maxWidth: width[index] ? width[index] : "auto",
                      }}
                    >
                      {item.content}
                    </div>
                  );
                }
              })}
            </div>
          ))}
          {data.length === 0 && <img src={noFound} alt="noFound" />}
        </div>
      </div>
    </div>
  );
}

export default Table;
