import { connect } from "react-redux";
// import { headerList } from 'data/routings';
import { Dropdown } from "antd";

// import HeaderLogo from 'assets/images/headerLogo.svg';
import angleDown from "assets/images/icons/angle-down.svg";

import "components/layout/media.css";
import "components/layout/style.css";
import { EXIT } from "store/actionTypes";
import { leadToCurrencyWithSymbol } from "helpers";
import { useTranslation } from "react-i18next";

function Header({ EXIT, user_data, activeCurrency }) {
  const { i18n } = useTranslation();
  const items = [
    {
      label: (
        <p style={{ color: "red" }} onClick={EXIT}>
          {i18n.t("Exit")}
        </p>
      ),
      key: "1",
    },
  ];

  return (
    user_data && (
      <div className="userHeader">
        <div className="userHeader_leftPart">
          {/* <img src={HeaderLogo} alt="HeaderLogo" /> */}
        </div>
        <div className="userHeader_rightPart">
          <p className="p14_600">
            Баланс:
            {leadToCurrencyWithSymbol(
              activeCurrency,
              Math.floor(user_data.balance).toFixed(0)
            )}
          </p>
          <Dropdown menu={{ items }} trigger={["click"]}>
            <a onClick={(e) => e.preventDefault()} className="userMenu">
              {user_data.image ? (
                <div
                  className="userHeader_rightPart__avatar"
                  style={{
                    backgroundImage: `url(${user_data.image.media.link})`,
                  }}
                />
              ) : (
                <div className="userHeader_rightPart__avatar__text p14_500">
                  {user_data.first_name[0].toUpperCase() +
                    user_data.last_name[0].toUpperCase()}
                </div>
              )}
              <img
                src={angleDown}
                alt="angleDown"
                className="userHeader_rightPart__menu"
              />
            </a>
          </Dropdown>
        </div>
      </div>
    )
  );
}

function mapStateToProps(state) {
  return {
    user_data: state.user.user_data,
    activeCurrency: state.user.activeCurrency,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    EXIT: () => dispatch({ type: EXIT }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
