import eye from "assets/images/icons/eye.svg";
import SimpleButton from "components/shared/buttons/simpleButton/SimpleButton";
import Table from "components/shared/table/Table";
import { distributionsStatus } from "data/statuses";
import { reviewInOrderTable } from "data/tableHeader";
import { reviewInOrderTableWidth } from "data/tableWidth";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { SET_VISIBLE_MODAL } from "store/actionTypes";
import { useTranslation } from "react-i18next";

const Verification = ({ order_by_id_data, setVisible }) => {
  const { id } = useParams();
  const { i18n } = useTranslation();
  const createReviewTableData = () => {
    return order_by_id_data.verifications.map((el) => [
      {
        type: "text",
        content: el.vendor_id || "-",
      },
      {
        type: "text",
        content: (
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            {el.desc || "-"}
          </div>
        ),
      },
      {
        type: "text",
        content: (
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            {el.vendor_description || "-"}
          </div>
        ),
      },
      {
        type: "text",
        content: (
          <div
            className="statusOnTable p12_400"
            style={{ backgroundColor: distributionsStatus[el.status].bg }}
          >
            {i18n.t(distributionsStatus[el.status].title)}
          </div>
        ),
      },
      {
        type: "actions",
        content: el.status === "REJECTED" && (
          <img
            src={eye}
            alt="eye"
            style={{ cursor: "pointer" }}
            onClick={() => setVisible({ data: el }, "verificationInfo")}
          />
        ),
      },
    ]);
  };

  return (
    <>
      <p className="p18_500 mainText p_mb8">{i18n.t("Checking")}</p>
      <Table
        header={reviewInOrderTable.map((e) => ({
          ...e,
          title: i18n.t(e.title),
        }))}
        width={reviewInOrderTableWidth}
        data={createReviewTableData()}
      />
      {(order_by_id_data.status === "IN_PROGRESS" ||
        order_by_id_data.status === "ON_REVISION") && (
        <div className="twoButton" style={{ marginTop: 16 }}>
          <div>
            <SimpleButton
              title={i18n.t("TheOrderHasBeenCompleted")}
              className="blueButton"
              onClick={() => {
                setVisible({ id }, "orderComplete");
              }}
            />
          </div>
          <div className="twoButton_item">
            {/* <SimpleButton
                            title={'Отказаться от предложения'}
                            className='redButton'
                            onClick={async () => {
                                await fetchRejectOrderById(id)
                                    .then(res => {
                                        if (res) navigate(-1)
                                    })
                            }}
                        /> */}
          </div>
        </div>
      )}
    </>
  );
};

function mapStateToProps(state) {
  return {
    order_by_id_data: state.orderById.order_by_id_data,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setVisible: (data, modal_type) =>
      dispatch({ type: SET_VISIBLE_MODAL, modal_type, data }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Verification);
