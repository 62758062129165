import EnterNewPassword from "components/components/forms/authentication/enterNewPassword/EnterNewPassword";
import AuthenticationBlock from "components/ui/authenticationBlock/AuthenticationBlock";
import TextHeading from "components/ui/teaxtHeading/TextHeading";
import "../style.css";
import { useTranslation } from "react-i18next";

function NewPassword() {
  const { i18n } = useTranslation();
  return (
    <AuthenticationBlock>
      <TextHeading
        text={i18n.t("PasswordRecovery")}
        style={{ textAlign: "center" }}
      />
      <div style={{ marginTop: 24 }}>
        <EnterNewPassword />
      </div>
    </AuthenticationBlock>
  );
}

export default NewPassword;
