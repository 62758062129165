import { notification } from "antd";
import SimpleButton from "components/shared/buttons/simpleButton/SimpleButton";
import InfoBlock from "components/ui/infoBlock/InfoBlock";
import { leadToCurrencyWithSymbol } from "helpers";
import { createQuerySecondType } from "hooks/createQuery";
import { warningNotification } from "hooks/notifications";
import { connect } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { CLOSE_MODAL } from "store/actionTypes";
import {
  fetchAllOperations,
  fetchOperationComplete,
  fetchOperationReject,
} from "store/actions/admin/operations";
import { useTranslation } from "react-i18next";

const OperationResponse = ({
  data,
  fetchOperationComplete,
  fetchOperationReject,
  fetchAllOperations,
  setVisible,
  activeCurrency,
}) => {
  const [api, contextHolder] = notification.useNotification();
  const [searchParams, setSearchParams] = useSearchParams();
  const { i18n } = useTranslation();

  return (
    <div>
      {contextHolder}
      <InfoBlock style={{ marginBottom: 24 }} className="infoBlock twoRow">
        <div style={{ width: "100%" }}>
          <p className="p12_400 mainText p_mb8">{i18n.t("UserEmail")}</p>
          <p className="p14_400 mainText">{data.user.email || "-"}</p>
        </div>
        <div style={{ width: "100%" }}>
          <p className="p12_400 mainText p_mb8">{i18n.t("Sum")}</p>
          <p className="p14_400 mainText">
            {leadToCurrencyWithSymbol(activeCurrency, data.amount) || "-"}
          </p>
        </div>
      </InfoBlock>
      <InfoBlock className="infoBlock twoRow">
        <div style={{ width: "100%" }}>
          <p className="p12_400 mainText p_mb8">
            {i18n.t("NameOfTheOrganization")}
          </p>
          <p className="p14_400 mainText p_mb16">
            {data.user.organization_name || "-"}
          </p>
          <p className="p12_400 mainText p_mb8">{i18n.t("LegalAddress")}</p>
          <p className="p14_400 mainText p_mb16">
            {data.user.legal_address || "-"}
          </p>
          <p className="p12_400 mainText p_mb8">{i18n.t("INN")}</p>
          <p className="p14_400 mainText p_mb16">{data.user.inn || "-"}</p>
          <p className="p12_400 mainText p_mb8">{i18n.t("KPP")}</p>
          <p className="p14_400 mainText p_mb16">{data.user.kpp || "-"}</p>
          <p className="p12_400 mainText p_mb8">{i18n.t("OGRN")}</p>
          <p className="p14_400 mainText">{data.user.ogrnip || "-"}</p>
        </div>
        <div style={{ width: "100%" }}>
          <p className="p12_400 mainText p_mb8">{i18n.t("NameOfTheBank")}</p>
          <p className="p14_400 mainText p_mb16">
            {data.user.bank_name || "-"}
          </p>
          <p className="p12_400 mainText p_mb8">{i18n.t("PaymentAccount")}</p>
          <p className="p14_400 mainText p_mb16">
            {data.user.bank_account || "-"}
          </p>
          <p className="p12_400 mainText p_mb8">{i18n.t("BICBank")}</p>
          <p className="p14_400 mainText p_mb16">{data.user.bic || "-"}</p>
          <p className="p12_400 mainText p_mb8">
            {i18n.t("CorrespondentAccountOfTheBank")}
          </p>
          <p className="p14_400 mainText">
            {data.user.correspondent_account || "-"}
          </p>
        </div>
      </InfoBlock>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: 15,
          justifyContent: "center",
          marginTop: 25,
        }}
      >
        <SimpleButton
          title={i18n.t("Confirm")}
          className="whiteButton"
          onClick={() =>
            warningNotification(
              api,
              async () => {
                await fetchOperationComplete(data.id);
                await fetchAllOperations(
                  Object.fromEntries([...searchParams]).page,
                  50,
                  createQuerySecondType(
                    Object.fromEntries([...searchParams]).search,
                    Object.fromEntries([...searchParams]).order_by,
                    Object.fromEntries([...searchParams]).order
                  )
                );
                api.destroy();
                setVisible();
              },
              i18n.t("notificationOfSendingToTheAccountingDepartment")
            )
          }
        />
        <SimpleButton
          title={i18n.t("Reject")}
          className="redButton"
          onClick={() =>
            warningNotification(
              api,
              async () => {
                await fetchOperationReject(data.id);
                await fetchAllOperations(
                  Object.fromEntries([...searchParams]).page,
                  50,
                  createQuerySecondType(
                    Object.fromEntries([...searchParams]).search,
                    Object.fromEntries([...searchParams]).order_by,
                    Object.fromEntries([...searchParams]).order
                  )
                );
                api.destroy();
                setVisible();
              },
              i18n.t("rejectionOfTheOperation")
            )
          }
        />
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    data: state.modal.data,
    activeCurrency: state.user.activeCurrency,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setVisible: () => dispatch({ type: CLOSE_MODAL }),
    fetchOperationComplete: (operation_id) =>
      dispatch(fetchOperationComplete(operation_id)),
    fetchOperationReject: (operation_id) =>
      dispatch(fetchOperationReject(operation_id)),
    fetchAllOperations: (page, size, params) =>
      dispatch(fetchAllOperations(page, size, params)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OperationResponse);
