import { Form, Input } from "antd";
import FormButton from "components/shared/buttons/formButton/FormButton";
import { useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { fetchPasswordReset } from "store/actions/login";
import { useTranslation } from "react-i18next";

function EnterNewPassword({ loading, fetchPasswordReset }) {
  const { i18n } = useTranslation();
  const [errorStatusFirst, setErrorStatusFirst] = useState({});
  const [errorStatusSecond, setErrorStatusSecond] = useState({});
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const onFinish = ({ replay_password, new_password }) => {
    if (replay_password === new_password) {
      const data = {
        token: searchParams.get("token"),
        new_password,
      };
      fetchPasswordReset(data).then((res) => {
        if (res === "success") navigate("/");
      });
    } else {
      setErrorStatusFirst({
        validateStatus: "error",
      });
      setErrorStatusSecond({
        validateStatus: "error",
        help: i18n.t("ThePasswordsDoNotMatch"),
      });
    }
  };

  return (
    <Form
      layout="vertical"
      requiredMark={false}
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      autoComplete="off"
      className="modalForm"
      onFieldsChange={() => {
        setErrorStatusFirst({});
        setErrorStatusSecond({});
      }}
    >
      <Form.Item
        label={i18n.t("NewPassword")}
        name="new_password"
        rules={[
          {
            required: true,
            message: i18n.t("ThisRequiredField"),
          },
        ]}
        {...errorStatusFirst}
      >
        <Input.Password placeholder={i18n.t("EnterPassword")} />
      </Form.Item>
      <Form.Item
        label={i18n.t("RepeatPassword")}
        name="replay_password"
        rules={[
          {
            required: true,
            message: i18n.t("ThisRequiredField"),
          },
        ]}
        {...errorStatusSecond}
      >
        <Input.Password placeholder={i18n.t("EnterPassword")} />
      </Form.Item>
      <Form.Item>
        <FormButton
          title={i18n.t("Recover")}
          style={{ width: "100%" }}
          htmlType="submit"
          loading={loading}
        />
      </Form.Item>
    </Form>
  );
}

function mapStateToProps(state) {
  return {
    loading: state.userEnv.loading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchPasswordReset: (data) => dispatch(fetchPasswordReset(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EnterNewPassword);
