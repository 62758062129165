import AddDocumentsOnOrder from 'components/components/modalChildrens/addDocumentsOnOrder/AddDocumentsOnOrder';
import ChangeCurrency from 'components/components/modalChildrens/changeCurrency/ChangeCurrency';
import CreateDistribution from 'components/components/modalChildrens/createDistribution/CreateDistribution';
import CreateOperation from 'components/components/modalChildrens/createOperation/CreateOperation';
import OperationResponse from 'components/components/modalChildrens/operationResponse/OperationResponse';
import OrderComplete from 'components/components/modalChildrens/orderComplete/OrderComplete';
import OrderDistribute from 'components/components/modalChildrens/orderDistribute/OrderDistribute';
import OrderReject from 'components/components/modalChildrens/orderReject/OrderReject';
import VerificationInfo from 'components/components/modalChildrens/verificationInfo/VerificationInfo';
import AcceptVerificationOrder from 'components/components/modalChildrens/verificationOrder/acceptVerificationOrder/AcceptVerificationOrder';
import VerificationOrder from 'components/components/modalChildrens/verificationOrder/mainVerificationOrder/VerificationOrder';
import RejectVerificationOrder from 'components/components/modalChildrens/verificationOrder/rejectVerificationOrder/RejectVerificationOrder';


export const modalChildrens = {
    orderComplete: <OrderComplete />,
    verificationOrder: <VerificationOrder />,
    rejectVerificationOrder: <RejectVerificationOrder />,
    acceptVerificationOrder: <AcceptVerificationOrder />,
    orderDistribute: <OrderDistribute />,
    createDistribution: <CreateDistribution />,
    orderReject: <OrderReject />,
    verificationInfo: <VerificationInfo />,
    createOperation: <CreateOperation />,
    operationResponse: <OperationResponse />,
    addDocumentsOnOrder: <AddDocumentsOnOrder />,
    changeCurrency: <ChangeCurrency />,
}