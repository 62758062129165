import { notification } from "antd";
import Breadcrumb from "components/shared/breadcrumb/Breadcrumb";
import SimpleButton from "components/shared/buttons/simpleButton/SimpleButton";
import Switch from "components/shared/switch/Switch";
import TextHeading from "components/ui/teaxtHeading/TextHeading";
import { adminVendersBreadcrumb } from "data/breadcrumb";
import { adminUserById } from "data/switchList";
import { createFio } from "hooks/createFio";
import { warningNotification } from "hooks/notifications";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ADMIN_CLEAR_USER_BY_ID_DATA } from "store/actionTypes";
import { fetchDeleteUser, fetchUserById } from "store/actions/admin/users";
import MainInfo from "./mainInfo/MainInfo";
import { useTranslation } from "react-i18next";

const VenderByIdAdmin = ({
  user_by_id_data,
  ADMIN_CLEAR_USER_BY_ID_DATA,
  fetchUserById,
  fetchDeleteUser,
}) => {
  const { i18n } = useTranslation();
  const { id } = useParams();
  const [api, contextHolder] = notification.useNotification();
  const [activeSwitch, setActiveSwitch] = useState("mainInfo");
  const [edit, setEdit] = useState(false);
  const [save, setSave] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchUserById(id);

    return () => {
      ADMIN_CLEAR_USER_BY_ID_DATA();
    };
  }, []);

  return (
    user_by_id_data && (
      <div className="contentBlock">
        {contextHolder}
        <Breadcrumb
          list={adminVendersBreadcrumb.map((e) => i18n.t(e.title))}
          activeLink={createFio(user_by_id_data)}
        />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <TextHeading
            text={`${i18n.t("Performer")} ${createFio(user_by_id_data)}`}
          />
          {edit ? (
            <div className="twoButton">
              <div>
                <SimpleButton
                  title={i18n.t("Cancel")}
                  className="whiteButton"
                  onClick={() => setEdit(false)}
                />
              </div>
              <div className="twoButton_item">
                <SimpleButton
                  title={i18n.t("Save")}
                  className="blueButton"
                  onClick={() => {
                    setSave(true);
                  }}
                />
              </div>
            </div>
          ) : (
            <div className="twoButton">
              <div>
                <SimpleButton
                  title={i18n.t("Remove")}
                  className="redButton"
                  onClick={() =>
                    warningNotification(
                      api,
                      async () => {
                        await fetchDeleteUser(id);
                        navigate(`/admin/venders`);
                        api.destroy();
                      },
                      i18n.t("removingPerformer")
                    )
                  }
                />
              </div>
              <div className="twoButton_item">
                <SimpleButton
                  title={i18n.t("Edit")}
                  className="blueButton"
                  onClick={() => {
                    setEdit(true);
                    setSave(false);
                  }}
                />
              </div>
            </div>
          )}
        </div>
        <Switch
          items={adminUserById.map((e) => ({ ...e, title: i18n.t(e.title) }))}
          activeItem={activeSwitch}
          onSwitch={setActiveSwitch}
          style={{
            marginTop: 24,
            marginBottom: 16,
          }}
          className="switch miniSwitch"
        />
        {activeSwitch === "mainInfo" && (
          <MainInfo
            edit={edit}
            save={save}
            onSave={() => setEdit(false)}
            onError={() => setSave(false)}
          />
        )}
      </div>
    )
  );
};

function mapStateToProps(state) {
  return {
    user_by_id_data: state.adminUserById.user_by_id_data,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchUserById: (id) => dispatch(fetchUserById(id)),
    fetchDeleteUser: (user_id) => dispatch(fetchDeleteUser(user_id)),
    ADMIN_CLEAR_USER_BY_ID_DATA: () =>
      dispatch({ type: ADMIN_CLEAR_USER_BY_ID_DATA }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(VenderByIdAdmin);
