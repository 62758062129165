import { Form, Input } from "antd";
import SimpleButton from "components/shared/buttons/simpleButton/SimpleButton";
import { useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CLOSE_MODAL } from "store/actionTypes";
import { fetchRejectOrderById } from "store/actions/user/orders";
import { useTranslation } from "react-i18next";

function OrderRejectForm({ data, setVisible, fetchRejectOrderById }) {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const [fields, setFields] = useState([
    {
      name: "rejection_reason",
      value: "",
    },
  ]);

  const onFinish = async ({ rejection_reason }) => {
    await fetchRejectOrderById(data.order_id, rejection_reason).then((res) => {
      if (res) {
        setVisible();
        navigate(-1);
      }
    });
  };

  return (
    <Form
      layout="vertical"
      requiredMark={false}
      initialValues={{
        remember: true,
      }}
      fields={fields}
      onFieldsChange={(_, allFields) => {
        setFields(allFields);
      }}
      onFinish={onFinish}
      autoComplete="off"
      className="modalForm"
    >
      <Form.Item
        label={i18n.t("ReasonRefusal")}
        name="rejection_reason"
        rules={[
          {
            required: true,
            message: i18n.t("ThisRequiredField"),
          },
        ]}
        style={{ width: "100%" }}
      >
        <Input placeholder={i18n.t("EnterReasonRefusal")} />
      </Form.Item>
      <Form.Item style={{ marginBottom: 0 }}>
        <div>
          <SimpleButton
            title={i18n.t("Cancel")}
            className="activeText p14_500 whiteButton"
            style={{
              marginRight: 16,
            }}
            onClick={setVisible}
          />
          <SimpleButton
            title={i18n.t("RefuseTheOffer")}
            className="p14_500 blueButton"
            htmltype="submit"
          />
        </div>
      </Form.Item>
    </Form>
  );
}

function mapStateToProps(state) {
  return {
    data: state.modal.data,
    venders: state.venders.venders,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setVisible: () => dispatch({ type: CLOSE_MODAL }),
    fetchRejectOrderById: (order_id, rejection_reason) =>
      dispatch(fetchRejectOrderById(order_id, rejection_reason)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderRejectForm);
