import { Form, Input } from "antd";
import FormButton from "components/shared/buttons/formButton/FormButton";
import { connect } from "react-redux";
import { fetchLogin } from "store/actions/login";
import { useTranslation } from "react-i18next";
function EmailLogin({ loading, fetchLogin }) {
  const { i18n } = useTranslation();
  const onFinish = (values) => {
    fetchLogin(values);
  };

  return (
    <Form
      layout="vertical"
      requiredMark={false}
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      autoComplete="off"
      className="modalForm"
    >
      <Form.Item
        label={i18n.t("EmailFull")}
        name="email"
        rules={[
          {
            required: true,
            message: i18n.t("ThisRequiredField"),
          },
        ]}
      >
        <Input placeholder={i18n.t("EnterMail")} />
      </Form.Item>
      <Form.Item
        label={i18n.t("Password")}
        name="password"
        rules={[
          {
            required: true,
            message: i18n.t("ThisRequiredField"),
          },
        ]}
      >
        <Input.Password placeholder={i18n.t("EnterPassword")} />
      </Form.Item>
      <Form.Item>
        <FormButton
          title={i18n.t("Login")}
          style={{ width: "100%" }}
          htmlType="submit"
          loading={loading}
        />
      </Form.Item>
    </Form>
  );
}

function mapStateToProps(state) {
  return {
    loading: state.userEnv.loading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchLogin: (data) => dispatch(fetchLogin(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailLogin);
