import Filter from "components/shared/filter/Filter";
import Pagination from "components/shared/pagination/Pagination";
import Table from "components/shared/table/Table";
import TextHeading from "components/ui/teaxtHeading/TextHeading";
import { userOrdersFilter } from "data/filterLIst";
import { ordersStatus } from "data/statuses";
import { allUserOrdersTable } from "data/tableHeader";
import { allUserOrdersTableWidth } from "data/tableWidth";
import { leadToCurrency } from "helpers";
import { createQuery } from "hooks/createQuery";
import { formatDateTime } from "hooks/formatDate";
import { useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { fetchAllAcceptedOrders } from "store/actions/user/orders";
import { useTranslation } from "react-i18next";

const AllOrders = ({
  orders,
  pages,
  fetchAllAcceptedOrders,
  activeCurrency,
}) => {
  const { i18n } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const query = Object.fromEntries([...searchParams]);
    if (query.page !== undefined) {
      fetchAllAcceptedOrders(
        query.page,
        50,
        createQuery(
          Object.fromEntries([...searchParams]).search,
          Object.fromEntries([...searchParams]).order_by,
          Object.fromEntries([...searchParams]).order
        )
      );
    } else {
      query.page = 1;
      setSearchParams(query);
    }
  }, [
    Object.fromEntries([...searchParams]).page,
    Object.fromEntries([...searchParams]).search,
    Object.fromEntries([...searchParams]).order_by,
    Object.fromEntries([...searchParams]).order,
  ]);

  const createTableData = () => {
    return orders.map((el) => [
      {
        type: "active_text",
        content: (
          <span onClick={() => navigate(`/user/my-orders/${el.id}`)}>
            {el.id}
          </span>
        ),
      },
      {
        type: "text",
        content: formatDateTime(el.time_created),
      },
      {
        type: "text",
        content: leadToCurrency(
          activeCurrency,
          (Math.round(el.commission_price * 10) / 10).toFixed(1)
        ),
      },
      {
        type: "actions",
        content: (
          <div
            className="statusOnTable p12_400"
            style={{ backgroundColor: ordersStatus[el.status].bg }}
          >
            {i18n.t(ordersStatus[el.status].title)}
          </div>
        ),
      },
    ]);
  };

  return (
    <div className="contentBlock">
      <div className="contentBlock_header">
        <div className="contentBlock_header__leftHalf">
          <TextHeading text={i18n.t("Orders")} />
        </div>
        <div className="contentBlock_header__rightHalf">
          <Filter
            className={"filter_marginRight"}
            filterList={userOrdersFilter.map((e) => ({
              ...e,
              title: i18n.t(e.title),
            }))}
          />
        </div>
      </div>
      <Table
        header={allUserOrdersTable.map((e) => ({
          ...e,
          title: i18n.t(e.title),
        }))}
        width={allUserOrdersTableWidth}
        data={createTableData()}
      />
      {orders.length > 0 && <Pagination pages={pages} />}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    orders: state.orderAccepted.orders,
    pages: state.orderAccepted.pages,
    activeCurrency: state.user.activeCurrency,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchAllAcceptedOrders: (page, size, params) =>
      dispatch(fetchAllAcceptedOrders(page, size, params)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AllOrders);
