import { useEffect } from "react";
import { connect } from 'react-redux';
import { Outlet, useLocation } from "react-router-dom";
// import { fetchWorkTypes } from "store/actions/workTypes";
// import { fetchTechnicTypes } from "store/actions/technicTypes";
// import { fetchSquareTypes } from "store/actions/squareTypes";
// import { fetchCropTypes } from "store/actions/cropTypes";
// import { fetchSowingTypes } from "store/actions/sowingTypes";

import './style.css';

function NoAuthLayout({
    // square_types_loading,
    // crop_types_loading,
    // sowing_types_loading,
    // work_types_loading,
    // technic_types_loading,
    // fetchWorkTypes, 
    // fetchTechnicTypes, 
    // fetchSquareTypes, 
    // fetchCropTypes, 
    // fetchSowingTypes 
}) {

    let location = useLocation();

    useEffect(() => {
        // fetchWorkTypes()
        // fetchTechnicTypes()
        // fetchSquareTypes()
        // fetchCropTypes()
        // fetchSowingTypes()
    }, [])

    return (
        // (!square_types_loading && !crop_types_loading && !sowing_types_loading && !work_types_loading && !technic_types_loading) &&
        <div className="noAuthLayout">
            <Outlet />
            {/* <Footer /> */}
        </div>
    );
}

function mapStateToProps(state) {
    return {
        // user_data: state.user.user_data,
        // access_token: state.userEnv.access_token,
        // square_types_loading: state.squareTypes.loading,
        // crop_types_loading: state.cropTypes.loading,
        // sowing_types_loading: state.sowingTypes.loading,
        // work_types_loading: state.workTypes.loading,
        // technic_types_loading: state.technicTypes.loading,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        // fetchWorkTypes: () => dispatch(fetchWorkTypes()),
        // fetchTechnicTypes: () => dispatch(fetchTechnicTypes()),
        // fetchSquareTypes: () => dispatch(fetchSquareTypes()),
        // fetchCropTypes: () => dispatch(fetchCropTypes()),
        // fetchSowingTypes: () => dispatch(fetchSowingTypes())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NoAuthLayout);