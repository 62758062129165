import Breadcrumb from "components/shared/breadcrumb/Breadcrumb";
import TextHeading from "components/ui/teaxtHeading/TextHeading";
import { userOrdersBreadcrumb } from "data/breadcrumb";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { CLEAR_ORDER_BY_ID_DATA } from "store/actionTypes";
import {
  fetchOrderById,
  fetchOrderPublicById,
} from "store/actions/user/orders";

import Switch from "components/shared/switch/Switch";
import { userOrderById } from "data/switchList";
import MainInfo from "./mainInfo/MainInfo";
import Verification from "./varification/Verification";
import { useTranslation } from "react-i18next";

const OrderById = ({
  order_by_id_data,
  fetchOrderById,
  fetchOrderPublicById,
  CLEAR_ORDER_BY_ID_DATA,
}) => {
  const { i18n } = useTranslation();
  const { id } = useParams();
  const [activeSwitch, setActiveSwitch] = useState("mainInfo");

  useEffect(() => {
    const url = window.location.pathname.split("/");
    if (url[url.length - 2] === "my-offers") {
      fetchOrderPublicById(id);
    } else {
      fetchOrderById(id);
    }

    return () => {
      CLEAR_ORDER_BY_ID_DATA();
    };
  }, []);

  return (
    order_by_id_data && (
      <div className="contentBlock">
        <Breadcrumb
          list={userOrdersBreadcrumb.map((e) => i18n.t(e.title))}
          activeLink={id}
        />
        <TextHeading text={`${i18n.t("Order")} ${id}`} />
        {(order_by_id_data.status !== "CREATED" ||
          order_by_id_data.status !== "DISTIBUTING") && (
          <Switch
            items={userOrderById.map((e) => ({ ...e, title: i18n.t(e.title) }))}
            activeItem={activeSwitch}
            onSwitch={setActiveSwitch}
            style={{
              marginTop: 24,
              marginBottom: 16,
            }}
            className="switch miniSwitch"
          />
        )}
        {activeSwitch === "mainInfo" && <MainInfo />}
        {activeSwitch === "verification" && <Verification />}
      </div>
    )
  );
};

function mapStateToProps(state) {
  return {
    order_by_id_data: state.orderById.order_by_id_data,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchOrderById: (id) => dispatch(fetchOrderById(id)),
    fetchOrderPublicById: (id) => dispatch(fetchOrderPublicById(id)),
    CLEAR_ORDER_BY_ID_DATA: () => dispatch({ type: CLEAR_ORDER_BY_ID_DATA }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderById);
