import AuthenticationBlock from "components/ui/authenticationBlock/AuthenticationBlock";
import TextHeading from "components/ui/teaxtHeading/TextHeading";
import { useState } from "react";

import PasswordRecoveryForm from "components/components/forms/authentication/passwordRecovery/PasswordRecoveryForm";
import { Link } from "react-router-dom";
import "../style.css";
import { useTranslation } from "react-i18next";

function PasswordRecovery() {
  const { i18n } = useTranslation();
  const [activeSwitch, setActiveSwitch] = useState("email");

  return (
    <AuthenticationBlock>
      <TextHeading
        text={i18n.t("ForgotYourPassword")}
        style={{ textAlign: "center" }}
      />
      <PasswordRecoveryForm />
      <Link to={"/login"} className="p14_400 activeText">
        {i18n.t("Login")}
      </Link>
    </AuthenticationBlock>
  );
}

export default PasswordRecovery;
