import { Form, InputNumber } from "antd";
import editPen from "assets/images/icons/edit_pen.svg";
import { leadToCurrency, leadToStandartCurrency } from "helpers";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchUpdateOrder } from "store/actions/admin/orders";
import { useTranslation } from "react-i18next";
function UpdateOrderPriceForm({
  // form,
  initial,
  onSave,
  name,
  max = null,
  fetchUpdateOrder,
  activeCurrency,
}) {
  const { i18n } = useTranslation();
  const { id } = useParams();
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    values[name] = leadToStandartCurrency(activeCurrency, values[name]);
    fetchUpdateOrder(id, values);
    onSave();
  };

  return (
    <Form
      layout="vertical"
      requiredMark={false}
      onFinish={onFinish}
      autoComplete="off"
      className="modalForm"
      initialValues={leadToCurrency(activeCurrency, initial)}
      form={form}
      style={{
        display: "flex",
        alignItems: "center",
        gap: 10,
        marginBottom: 16,
      }}
    >
      <Form.Item
        name={name}
        rules={[
          {
            required: true,
            message: i18n.t("ThisRequiredField"),
          },
        ]}
        style={{
          marginBottom: 0,
        }}
      >
        <InputNumber placeholder={i18n.t("EnterCost")} max={max} />
      </Form.Item>
      <img
        src={editPen}
        alt="editPen"
        width={15}
        style={{ cursor: "pointer" }}
        onClick={() => {
          form.submit();
        }}
      />
    </Form>
  );
}

function mapStateToProps(state) {
  return {
    activeCurrency: state.user.activeCurrency,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchUpdateOrder: (order_id, data) =>
      dispatch(fetchUpdateOrder(order_id, data)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateOrderPriceForm);
