import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Form, Input, Button, Checkbox, Switch } from "antd";
import { SET_VISIBLE_MODAL } from "store/actionTypes";
import {
  createCurrency,
  deleteCurrency,
  getAllCurrencies,
} from "store/actions/admin/currency";
import SimpleButton from "components/shared/buttons/simpleButton/SimpleButton";
import "./style.css";
import { useTranslation } from "react-i18next";

const ChangeCurrency = ({
  getAllCurrencies,
  currencies,
  deleteCurrency,
  createCurrency,
  setVisible,
}) => {
  const { i18n } = useTranslation();
  const [currencyList, setCurrencyList] = useState([]);

  useEffect(() => {
    if (currencies) {
      setCurrencyList(currencies);
    }
  }, [currencies]);

  const addCurrency = () => {
    setCurrencyList([
      ...currencyList,
      {
        id: Date.now(),
        code: "",
        name: "",
        rate: "",
        short: "",
        symbol: "",
        is_active: true,
        isNew: true,
      }, // Временный ID
    ]);
  };

  const close = () => {
    setVisible();
  };

  const remove = (id) => {
    let element = currencyList.find(
      (currency) => currency.id === id && currency.isNew
    );
    if (element) {
      setCurrencyList(currencyList.filter((currency) => currency.id !== id));
    } else {
      deleteCurrency(id);
    }
  };

  const change = (id, field, value) => {
    setCurrencyList(
      currencyList.map((currency) =>
        currency.id === id ? { ...currency, [field]: value } : currency
      )
    );
  };

  const save = () => {
    const newCurrencies = currencyList
      .filter((currency) => currency.isNew)
      .map((e) => {
        delete e.id;
        return e;
      });
    const existingCurrencies = currencyList.filter(
      (currency) => !currency.isNew
    );

    createCurrency([...existingCurrencies, ...newCurrencies], setVisible);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        maxWidth: 900,
        width: 500,
        minHeight: 300,
      }}
    >
      <Form
        layout="vertical"
        autoComplete="off"
        className="modalForm"
        style={{ marginBottom: 24 }}
      >
        {currencyList.map((currency) => (
          <div
            key={currency.id}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: 10,
              gap: 20,
              borderBottom: "1px solid #dedede",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "flex-end",
                flexWrap: "wrap",
                marginBottom: 10,
                gap: 10,
              }}
            >
              <Form.Item
                label={i18n.t("CurrencyCode")}
                style={{ minWidth: 150, flex: 1, marginBottom: 0 }}
              >
                <Input
                  placeholder={i18n.t("CurrencyCodeRUB")}
                  value={currency.code}
                  onChange={(e) => change(currency.id, "code", e.target.value)}
                />
              </Form.Item>
              <Form.Item
                label={i18n.t("CurrencyExchangeRate")}
                style={{ minWidth: 150, flex: 1, marginBottom: 0 }}
              >
                <Input
                  placeholder={i18n.t("CurrencyExchangeRate")}
                  value={currency.rate}
                  onChange={(e) => change(currency.id, "rate", e.target.value)}
                />
              </Form.Item>
              <Form.Item
                label={i18n.t("NameCurrency")}
                style={{ minWidth: 150, flex: 1, marginBottom: 0 }}
              >
                <Input
                  placeholder={i18n.t("NameCurrencyRUB")}
                  value={currency.name}
                  onChange={(e) => change(currency.id, "name", e.target.value)}
                />
              </Form.Item>
              <Form.Item
                label={i18n.t("CurrencySymbol")}
                style={{ minWidth: 150, flex: 1, marginBottom: 0 }}
              >
                <Input
                  placeholder={i18n.t("CurrencySymbolRUB")}
                  value={currency.symbol}
                  onChange={(e) =>
                    change(currency.id, "symbol", e.target.value)
                  }
                />
              </Form.Item>
              <Form.Item
                label={i18n.t("ShortName")}
                style={{ minWidth: 150, flex: 1, marginBottom: 0 }}
              >
                <Input
                  placeholder={i18n.t("ShortNameRUB")}
                  value={currency.short}
                  onChange={(e) => change(currency.id, "short", e.target.value)}
                />
              </Form.Item>
              {/* <Form.Item
                label={i18n.t("Active")}
                style={{ minWidth: 150, flex: 1, marginBottom: 0 }}
              >
                <Switch
                  defaultChecked
                  checked={currency.is_active}
                  onChange={(e) => change(currency.id, "is_active", e)}
                />
              </Form.Item> */}
            </div>
            <Button danger onClick={() => remove(currency.id)}>
              {i18n.t("Remove")}
            </Button>
          </div>
        ))}
      </Form>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "auto",
          gap: 10,
        }}
      >
        <SimpleButton
          title={i18n.t("AddCurrency")}
          className="blueButton"
          style={{ marginRight: "auto" }}
          onClick={addCurrency}
        />
        <SimpleButton
          title={i18n.t("Save")}
          className="whiteButton"
          onClick={save}
        />
        <SimpleButton
          title={i18n.t("Close")}
          className="redButton"
          onClick={close}
        />
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    currencies: state.currency.currencies,
    loading: state.currency.loading,
    fetch: state.currency.fetch,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setVisible: (modal_type, data) =>
      dispatch({ type: SET_VISIBLE_MODAL, modal_type, data }),
    getAllCurrencies: () => dispatch(getAllCurrencies()),
    deleteCurrency: (id) => dispatch(deleteCurrency(id)),
    createCurrency: (currencies, setVisible) =>
      dispatch(createCurrency(currencies, setVisible)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangeCurrency);
